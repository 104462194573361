import { EditIcon, EllipsisIcon, PlusCircleIcon, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

import { useAppSelector } from '../redux/hooks.ts';
import {
  clearToastMessage,
  selectCameraList,
  selectToastMessage,
  selectSelectedCamera,
  sendAddCamera,
  sendGetCameraList,
  sendRemoveCamera,
  sendSwitchCamera,
  sendUpdateCamera,
  selectIsDeviceConnected,
} from '../redux/ms.slice.ts';
import { Camera, CameraValues } from '../lib/messages';

import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from './ui/Sheet.tsx';
import { Button } from './ui/Button.tsx';
import { RadioGroup } from './ui/RadioGroup.tsx';
import { CameraRadioItem } from './CameraRadioItem.tsx';
import { CameraEditForm } from './CameraEditForm.tsx';

export const DeviceMenuDrawer = () => {
  const isDeviceConnected = useAppSelector(selectIsDeviceConnected);
  const [editMode, setEditMode] = useState(false);
  const [isAddingCamera, setIsAddingCamera] = useState(false);
  const cameraList = useAppSelector(selectCameraList);
  const toastMessage = useAppSelector(selectToastMessage);
  const selectedCamera = useAppSelector(selectSelectedCamera);

  const dispatch = useDispatch();

  const handleEditToggle = () => {
    setEditMode(!editMode);
    setIsAddingCamera(false);
  };

  const handleOpen = () => {
    dispatch(sendGetCameraList());
  };

  const handleAddCameraClick = () => {
    setIsAddingCamera(true);
  };

  const handleAddCameraSave = (camera: CameraValues) => {
    dispatch(sendAddCamera(camera));
    setIsAddingCamera(false);
    setEditMode(false);
  };

  const handleAddCameraCancel = () => {
    setIsAddingCamera(false);
  };

  const handleCameraSave = (camera: Camera) => {
    dispatch(sendUpdateCamera(camera));
    setEditMode(false);
  };

  const handleCameraRemove = (camera: Camera) => {
    dispatch(sendRemoveCamera(camera));
    setEditMode(false);
  };

  const handleCameraSwitch = (value: string) => {
    const camera = cameraList.find((camera) => camera.id === value);

    if (camera) {
      dispatch(sendSwitchCamera(camera));
    }
    setEditMode(false);
  };

  const EditModeIcon = editMode ? XIcon : EditIcon;

  useEffect(() => {
    if (toastMessage) {
      toast(toastMessage.label, {
        description: capitalize(toastMessage.description),
      });
    }
  }, [toastMessage]);

  return (
    <Sheet
      onOpenChange={(open) => {
        if (open) {
          handleOpen();
        } else {
          dispatch(clearToastMessage());
        }
      }}
    >
      <SheetTrigger asChild>
        <Button size="icon" disabled={!isDeviceConnected} variant="ghost">
          <EllipsisIcon className="drop-shadow-[-1px_1px_0px_rgba(0,0,0,0.25) size-6 text-white" />
        </Button>
      </SheetTrigger>
      <SheetContent className="flex gap-0 p-0 md:min-w-[500px]">
        <div className="flex max-w-full flex-col gap-8 p-6">
          <SheetHeader className="flex flex-row items-center justify-between gap-3">
            <div>
              <SheetTitle className="pt-10">Camera</SheetTitle>
              <SheetDescription>
                Switch between available camera feeds and view and manage your connected cameras.
              </SheetDescription>
            </div>
            <div>
              {!isEmpty(cameraList) && (
                <Button variant="ghost" size="icon" onClick={handleEditToggle}>
                  <EditModeIcon size={18} />
                </Button>
              )}
            </div>
          </SheetHeader>
          <div className="flex w-full flex-col gap-2">
            <RadioGroup value={selectedCamera?.id} onValueChange={handleCameraSwitch}>
              {cameraList.map((camera) => (
                <CameraRadioItem
                  key={camera.id}
                  label={camera.name}
                  value={camera.id}
                  id={camera.id}
                  rtspUrl={camera.rtsp_url}
                  editMode={editMode}
                  networkInterface={camera.network_interface}
                  onSave={handleCameraSave}
                  onRemove={handleCameraRemove}
                />
              ))}
            </RadioGroup>
            {!isAddingCamera && (
              <Button variant="ghost" className="mt-4 gap-2 self-start" onClick={handleAddCameraClick}>
                <PlusCircleIcon size={18} /> Add Camera
              </Button>
            )}

            {/* Add Camera Form */}
            {isAddingCamera && (
              <CameraEditForm
                title="Add Camera"
                initialValues={{ name: '', rtsp_url: '', network_interface: '' }}
                onSave={handleAddCameraSave}
                onCancel={handleAddCameraCancel}
              />
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
