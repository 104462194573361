import { PayloadAction } from '@reduxjs/toolkit';
import { all, delay, put, select, takeEvery } from 'redux-saga/effects';

import * as webrtcSlice from 'src/redux/webrtc.slice.ts';

import * as msSlice from './ms.slice.ts';
import { selectIsWebsocketConnected, selectDeviceId, selectWebsocketConnectionRetryCount } from './ms.slice.ts';

function* startWebRtcSessionSaga() {
  yield put(msSlice.sendPiStatusRequest());
  yield put(msSlice.sendPiModemInfoRequest());
  yield put(webrtcSlice.startPeerConnection());
  yield put(msSlice.sendStartWebRtcSessionRequest());
}

function* sendOfferSaga({ payload }: PayloadAction<RTCSessionDescriptionInit>) {
  yield put(msSlice.sendOffer(payload));
}

function* sendAnswerSaga({ payload }: PayloadAction<RTCSessionDescriptionInit>) {
  yield put(msSlice.sendAnswer(payload));
}

function* sendIceCandidateSaga({ payload }: PayloadAction<RTCIceCandidateInit>) {
  yield put(msSlice.sendIceCandidate(payload));
}

function* listenForPiAnswerSaga({ payload }: PayloadAction<RTCSessionDescriptionInit>) {
  yield put(webrtcSlice.acceptAnswer(payload));
}
function* listenForPiOfferSaga({ payload }: PayloadAction<RTCSessionDescriptionInit>) {
  yield put(webrtcSlice.acceptOffer(payload));
}

function* listenForPiIceCandidateSaga({ payload }: PayloadAction<RTCIceCandidateInit>) {
  yield put(webrtcSlice.setIceCandidate(payload));
}

function* restartWebsocketConnectionSaga() {
  const deviceId = selectDeviceId(yield select());

  if (!deviceId) {
    console.warn('Unable to start connection, deviceId is null');
    return;
  }

  const retryCount = selectWebsocketConnectionRetryCount(yield select());
  const isConnected = selectIsWebsocketConnected(yield select()); // Replace it with your actual selector

  if (!isConnected && retryCount < 5) {
    const backoffTime = exponentialBackoff(retryCount);
    yield delay(backoffTime);
    yield put(msSlice.connectToWebsocket(deviceId));
  } else {
    yield put(msSlice.receivedWebsocketMaxRetriesReached());
  }
}

function* stopWebrtcSessionSaga() {
  yield put(webrtcSlice.disconnect());
}

// Helper function to calculate the backoff time
function exponentialBackoff(retryCount: number, baseDelay = 1000, maxDelay = 16000) {
  return Math.min(baseDelay * 2 ** retryCount, maxDelay);
}

export default function* msSaga() {
  yield all([
    takeEvery(msSlice.receivedPiConnected.toString(), startWebRtcSessionSaga),
    // takeEvery(msSlice.receivedPiCameraOnline.toString(), sendWebrtcOfferSaga),
    takeEvery(msSlice.receivedPiAnswer.toString(), listenForPiAnswerSaga),
    takeEvery(msSlice.receivedPiWebrtcOffer.toString(), listenForPiOfferSaga),
    takeEvery(msSlice.receivedPiIceCandidate.toString(), listenForPiIceCandidateSaga),
    takeEvery(webrtcSlice.offerCreated.toString(), sendOfferSaga),
    takeEvery(webrtcSlice.answerCreated.toString(), sendAnswerSaga),
    takeEvery(webrtcSlice.receivedIceCandidate.toString(), sendIceCandidateSaga),
    takeEvery(msSlice.receiveWebsocketClosedUnexpectedly.toString(), restartWebsocketConnectionSaga),
    takeEvery(msSlice.receivedWebRtcPeerDisconnected.toString(), startWebRtcSessionSaga),
    takeEvery(msSlice.clear.toString(), stopWebrtcSessionSaga),
  ]);
}
