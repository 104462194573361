import * as React from 'react';
import { useState, forwardRef } from 'react';
import { PencilIcon, XIcon } from 'lucide-react';

import { cn } from 'src/lib/utils';

import { Camera, CameraValues } from '../lib/messages'; // Import the new component

import { RadioGroupItem } from './ui/RadioGroup';
import { Label } from './ui/Label';
import { Button } from './ui/Button';
import { CameraEditForm } from './CameraEditForm';

type Props = {
  label: string;
  value: string;
  id: string;
  editMode: boolean;
  rtspUrl: string;
  networkInterface: string;
  onRemove: (camera: Camera) => void;
  onSave: (camera: Camera) => void;
} & React.ComponentPropsWithoutRef<typeof RadioGroupItem>;

export const CameraRadioItem = forwardRef<React.ElementRef<typeof RadioGroupItem>, Props>(
  ({ label, value, id, rtspUrl, networkInterface, editMode, onRemove, onSave }, ref) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = (values: CameraValues) => {
      onSave({ id, ...values });
      setIsEditing(false);
    };

    const handleCancel = () => {
      setIsEditing(false);
    };

    return (
      <div className="flex flex-col">
        <div
          className={cn(
            'flex relative items-center space-x-2 border rounded-lg p-4 gap-4 shadow-sm transition-transform',
            {
              'animate-jiggle': editMode,
              'opacity-8': isEditing || editMode,
            },
          )}
        >
          {editMode && (
            <div className="absolute -right-2 -top-4">
              <Button
                size="icon"
                variant="ghost"
                className="size-7 rounded-full bg-secondary p-1 shadow-md"
                onClick={() =>
                  onRemove({ id: value, name: label, rtsp_url: rtspUrl, network_interface: networkInterface })
                }
              >
                <XIcon size={16} className="text-muted-foreground" />
              </Button>
            </div>
          )}
          <RadioGroupItem
            disabled={isEditing || editMode}
            ref={ref}
            value={value}
            id={id}
            className="focus:ring-primary focus:ring-offset-2"
          />
          <div className="flex grow flex-col">
            <Label htmlFor={id} className="text-lg font-semibold">
              {label}
            </Label>
            <span className="hidden text-sm text-muted-foreground md:block">{rtspUrl}</span>
          </div>
          <Button
            disabled={editMode}
            variant="ghost"
            size="icon"
            className="ml-auto p-1 text-muted-foreground hover:text-foreground"
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? <XIcon size={18} /> : <PencilIcon size={18} />}
          </Button>
        </div>

        {isEditing && (
          <CameraEditForm
            title="Edit Camera"
            initialValues={{ name: label, rtsp_url: rtspUrl, network_interface: networkInterface }}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        )}
      </div>
    );
  },
);

CameraRadioItem.displayName = 'CameraRadioItem';
