import { createRoutesFromElements, createBrowserRouter, Route, RouterProvider } from 'react-router-dom';

import ErrorPage from './components/ErrorPage';
import { IndexPage } from './components/IndexPage';
import { NotFoundPage } from './components/NotFoundPage';
import { RegisterDevicePage } from './components/RegisterDevicePage';
import { SettingsGeneralPage } from './components/SettingsGeneralPage';
import { SettingsLayout } from './components/SettingsLayout';
import { SignInPage } from './components/SignInPage';
import { SignedInLayout } from './components/SignedInLayout.tsx';
import { DevicePage } from './components/DevicePage';
import { SignedOutLayout } from './components/SignedOutLayout.tsx';
import AuthCallbackPage from './components/AuthCallbackPage.tsx';
import { SignOutPage } from './components/SignOutPage.tsx';
import { VerifyPage } from './components/VerifyPage.tsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorPage />}>
      <Route element={<SignedOutLayout />}>
        <Route path="sign-in/*" element={<SignInPage />} />
        <Route path="auth/callback" element={<AuthCallbackPage />} />
      </Route>
      <Route element={<SignedInLayout />}>
        <Route path="sign-out" element={<SignOutPage />} />
        <Route index element={<IndexPage />} />
        <Route path="devices/create" element={<RegisterDevicePage />} />
        <Route path="devices/:device_id" element={<DevicePage />} />
        <Route path="verify/:token" element={<VerifyPage />} />
        <Route element={<SettingsLayout />}>
          <Route path="devices/:device_id/settings/general" element={<SettingsGeneralPage />} />
          <Route path="devices/:device_id/settings/modem" element={<div />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
);

export function App() {
  return <RouterProvider router={router} />;
}
