import { useEffect } from 'react';

import { useAppDispatch } from 'src/redux/hooks';
import { clear, connectToWebsocket, disconnectWebsocket } from 'src/redux/ms.slice.ts';

export const useDeviceWebsocketEffect = (deviceId: string | undefined) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener('online', () => {
      if (!deviceId) return;

      dispatch(clear());
      dispatch(connectToWebsocket(deviceId));
    });

    if (!deviceId) return;
    dispatch(clear());
    dispatch(connectToWebsocket(deviceId));
    return () => {
      dispatch(disconnectWebsocket());
    };
  }, [dispatch, deviceId]);
};
