import { LogsIcon } from 'lucide-react';

import { selectLogs } from '../redux/ms.slice.ts';
import { useAppSelector } from '../redux/hooks.ts';

import { Logs } from './Logs.tsx';
import { Popover, PopoverContent, PopoverTrigger } from './ui/Popover.tsx';
import { Button } from './ui/Button.tsx';

export const ShowLogsButton = () => {
  const logs = useAppSelector(selectLogs);
  return (
    <Popover>
      <PopoverTrigger asChild className="z-50 text-white">
        <Button size="icon" variant="ghost" className="stroke-1">
          <LogsIcon className="size-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        alignOffset={48}
        align="end"
        className="max-h-[80%] w-full max-w-[100vw] overflow-auto border-gray-50/20 bg-black"
      >
        <Logs logs={logs} className="max-h-[600px]" />
      </PopoverContent>
    </Popover>
  );
};
