import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDeviceWebsocketEffect } from 'src/hooks/useDeviceWebsocketEffect.ts';
import { useGetDeviceQuery } from 'src/redux/devices.api.ts';
import { useAppSelector } from 'src/redux/hooks.ts';
import { selectWebrtcStats, selectWebrtcTrack } from 'src/redux/webrtc.slice.ts';
import { ApiError } from 'src/redux/types.ts';

import { LiveVideo } from './LiveVideo.tsx';
import { DeviceHeader } from './DeviceHeader.tsx';
import { DeviceFooter } from './DeviceFooter.tsx';
import { DeviceStatusPanel } from './DeviceStatusPanel.tsx';
import { useTheme } from './ThemeProvider.tsx';

export function DevicePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { device_id } = useParams<{ device_id: string }>();
  const { data: device, error } = useGetDeviceQuery(String(device_id));
  const track = useAppSelector(selectWebrtcTrack);
  const stats = useAppSelector(selectWebrtcStats);

  const { theme, setTheme } = useTheme();

  useEffect(() => {
    const prevTheme = theme;
    setTheme('dark');
    return () => setTheme(prevTheme);
    // We need to run this only once to set theme
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeviceWebsocketEffect(device_id);

  useEffect(() => {
    if (error && (error as ApiError).status === 404) navigate('/404');
  }, [error, navigate]);

  return (
    <main className="flex h-full flex-col">
      <Helmet>
        <title>Device: {device?.name ?? ''}</title>
      </Helmet>
      <DeviceHeader />
      <div className="relative flex grow flex-col items-center justify-center">
        {(!stats || location.hash.includes('details-overlay')) && (
          <DeviceStatusPanel deviceName={device?.name} className="absolute z-10" />
        )}
        {track && <LiveVideo className="max-h-screen w-full" track={track} />}
      </div>
      <DeviceFooter />
    </main>
  );
}
