import * as React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/hooks.ts';
import { selectNetworkInterfaceNames, sendGetNetworkInterfaces } from '../redux/ms.slice.ts';

import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './ui/Card';
import { Label } from './ui/Label';
import { Select, SelectTrigger, SelectContent, SelectItem } from './ui/Select';

const formSchema = z.object({
  name: z.string().min(1, 'Camera name is required'),
  rtsp_url: z
    .string()
    .url('Invalid URL format')
    .min(1, 'RTSP URL is required')
    .refine((url) => url.startsWith('rtsp://'), {
      message: 'URL must start with "rtsp://"',
    }),
  network_interface: z.string().min(1, 'Network interface is required'),
});

type FormValues = z.infer<typeof formSchema>;

type CameraEditFormProps = {
  title: string;
  initialValues: FormValues;
  onSave: (data: FormValues) => void;
  onCancel: () => void;
};

export const CameraEditForm: React.FC<CameraEditFormProps> = ({ title, initialValues, onSave, onCancel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });
  const dispatch = useAppDispatch();
  const networkInterfaceNames = useAppSelector(selectNetworkInterfaceNames);

  useEffect(() => {
    dispatch(sendGetNetworkInterfaces());
  }, [dispatch]);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onSave(values);
  };

  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <div className="mb-4">
            <Label htmlFor="camera-name">Name</Label>
            <Input id="camera-name" {...register('name')} className="w-full" placeholder="SIYI Camera" />
            {errors.name && <p className="mt-1 text-sm text-destructive">{errors.name.message}</p>}
          </div>
          <div className="mb-4">
            <Label htmlFor="camera-url">RTSP URL</Label>
            <Input
              id="camera-url"
              {...register('rtsp_url')}
              className="w-full"
              placeholder="rtsp://192.168.1.10:554/stream"
            />
            {errors.rtsp_url && <p className="mt-1 text-sm text-destructive">{errors.rtsp_url.message}</p>}
          </div>
          <div className="mb-4">
            <Label htmlFor="network-interface">Network Interface</Label>
            <Controller
              name="network_interface"
              control={control}
              disabled={!networkInterfaceNames}
              render={({ field }) => (
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <SelectTrigger id="network-interface" className="w-full">
                    <span className="block truncate">{field.value || 'Select an interface'}</span>
                  </SelectTrigger>
                  <SelectContent>
                    {networkInterfaceNames?.map((name: string) => (
                      <SelectItem key={name} value={name}>
                        {name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
            {errors.network_interface && (
              <p className="mt-1 text-sm text-destructive">{errors.network_interface.message}</p>
            )}
          </div>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button variant="ghost" type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="default" type="submit">
            Save changes
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};
