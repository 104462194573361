import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { ModeToggleButton } from './ModeToggleButton.tsx';
import { UserDropdown } from './UserDropdown.tsx';

export const AppHeader: FC<PropsWithChildren> = ({ children }) => {
  const user = localStorage.getItem('user');
  return (
    <header className="sticky top-0 z-50 w-full border-border/40 bg-background/95 flex flex-col items-center backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-16 max-w-screen-2xl items-center gap-6 text-sm px-4">
        <div>
          <Link to="/" className="font-medium lg:inline-block">
            AirCast
          </Link>
        </div>
        <div className="grow flex gap-6">{children}</div>
        <div className="flex items-center gap-4">
          <ModeToggleButton />
          {user && <UserDropdown user={JSON.parse(user)} />}
        </div>
      </div>
    </header>
  );
};
