import byteSize from 'byte-size';
import { NotebookTextIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks.ts';
import { selectWebrtcStats } from '../redux/webrtc.slice.ts';

import { Toggle } from './ui/Toggle.tsx';
import { ShowLogsButton } from './ShowLogsButton.tsx';

export const DeviceFooter = () => {
  const stats = useAppSelector(selectWebrtcStats);
  const location = useLocation();
  const navigate = useNavigate();

  const isDetailsOverlayEnabled = location.hash.includes('details-overlay');

  return (
    <footer className="fixed bottom-12 left-0 box-border flex w-full flex-wrap justify-between gap-2 px-4 text-sm [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] md:gap-8">
      <Toggle
        className="px-2"
        pressed={isDetailsOverlayEnabled}
        onPressedChange={() => navigate(!isDetailsOverlayEnabled ? '#details-overlay' : '#', { replace: true })}
      >
        <NotebookTextIcon className="size-5" />
      </Toggle>
      <div className="flex grow flex-wrap justify-center gap-8">
        {stats?.framesPerSecond && (
          <div className="flex items-center gap-2">
            <div className="font-bold">{stats?.framesPerSecond} FPS</div>
          </div>
        )}
        {stats && (
          <div className="flex min-w-[160px] items-center gap-2">
            <div className="font-bold">{`${byteSize(stats.bitrate).toString()}/s`}</div>
            <div className="font-bold">{`${byteSize(stats.bytesReceived ?? 0).toString()}`}</div>
          </div>
        )}
      </div>
      <ShowLogsButton />
    </footer>
  );
};
