import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { API_URL } from 'src/env';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: 'include',
});

const baseQueryWith401Handling: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    location.replace('/sign-out');
  }

  return result;
};

export default baseQueryWith401Handling;
