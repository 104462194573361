import { PlugZapIcon, RotateCcwIcon } from 'lucide-react';

import { sendReboot } from '../redux/ms.slice.ts';
import { useAppDispatch } from '../redux/hooks.ts';

import { Button } from './ui/Button.tsx';

type Props = {
  deviceName?: string;
};

export const DeviceStatusItem = ({ deviceName }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex items-center gap-3">
      <div>
        <PlugZapIcon size={32} />
      </div>
      <div className="grow">
        <div>{deviceName ?? 'Device'}: Connected</div>
      </div>
      <Button variant="ghost" className="gap-3" onClick={() => dispatch(sendReboot())}>
        <RotateCcwIcon size={16} className="drop-shadow-[-1px_1px_0px_rgba(0,0,0,0.25)" />
        Reboot
      </Button>
    </div>
  );
};
