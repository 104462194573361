import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, LogOut, CheckCircle2, AlertCircle } from 'lucide-react';

import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from './ui/Card.tsx';
import { Button } from './ui/Button.tsx';
import { Progress } from './ui/Progress';
import { Alert, AlertDescription } from './ui/Alert';
import { API_URL } from '../env.ts';

type SignOutState = 'initial' | 'processing' | 'success' | 'error';

export const SignOutPage = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<SignOutState>('initial');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [remainingTime, setRemainingTime] = useState(3);

  useEffect(() => {
    const handleSignOut = async () => {
      setState('processing');
      setProgress(33);

      try {
        // Clear local storage
        localStorage.removeItem('user');
        localStorage.removeItem('session_token');
        setProgress(66);

        // Attempt server sign out
        const response = await fetch(API_URL + '/auth/sign-out', {
          method: 'POST',
          credentials: 'include',
        });

        // Consider both 200-range responses and 401 as successful outcomes
        if (!response.ok && response.status !== 401) {
          throw new Error('Failed to sign out properly');
        }

        setProgress(100);
        setState('success');

        // Countdown timer
        const timer = setInterval(() => {
          setRemainingTime((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              navigate('/sign-in');
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      } catch (error) {
        console.error('Error signing out:', error);
        setState('error');
        setError(error instanceof Error ? error.message : 'An unexpected error occurred');
      }
    };

    handleSignOut().catch(console.error);
  }, [navigate]);

  const getStateContent = () => {
    switch (state) {
      case 'initial':
        return {
          icon: <LogOut className="h-8 w-8 text-muted-foreground" />,
          title: 'Preparing to sign out',
          description: 'Please wait while we prepare to sign you out...',
        };
      case 'processing':
        return {
          icon: <Loader2 className="h-8 w-8 animate-spin text-primary" />,
          title: 'Signing you out',
          description: 'Securely closing your session...',
        };
      case 'success':
        return {
          icon: <CheckCircle2 className="h-8 w-8 text-green-500" />,
          title: 'Successfully signed out',
          description: `Redirecting in ${remainingTime} seconds...`,
        };
      case 'error':
        return {
          icon: <AlertCircle className="h-8 w-8 text-destructive" />,
          title: 'Error signing out',
          description: 'There was a problem signing you out.',
        };
    }
  };

  const content = getStateContent();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-background p-4">
      <Card className="w-full max-w-md shadow-sm">
        <CardHeader className="text-center space-y-2">
          <div className="flex justify-center mb-2">{content.icon}</div>
          <CardTitle>{content.title}</CardTitle>
          <CardDescription>{content.description}</CardDescription>
        </CardHeader>

        <CardContent className="space-y-4">
          {state === 'processing' && (
            <div className="space-y-2">
              <Progress value={progress} className="h-2" />
              <p className="text-xs text-center text-muted-foreground">{progress}% complete</p>
            </div>
          )}

          {state === 'error' && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        </CardContent>

        <CardFooter>
          {state === 'error' ? (
            <div className="w-full space-y-2">
              <Button variant="destructive" className="w-full" onClick={() => window.location.reload()}>
                Try Again
              </Button>
              <Button variant="outline" className="w-full" onClick={() => navigate('/sign-in')}>
                Return to Sign In
              </Button>
            </div>
          ) : (
            <Button disabled className="w-full">
              {state === 'success' ? 'Redirecting...' : 'Signing Out...'}
            </Button>
          )}
        </CardFooter>
      </Card>

      {/* Optional help text */}
      <p className="mt-4 text-sm text-muted-foreground text-center">
        Having trouble?{' '}
        <a href="/support" className="text-primary hover:underline">
          Contact support
        </a>
      </p>
    </div>
  );
};
