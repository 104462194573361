import { zodResolver } from '@hookform/resolvers/zod';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { Loader2 } from 'lucide-react';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormProvider,
} from 'src/components/ui/Form.tsx';
import { Input } from 'src/components/ui/Input.tsx';
import { Device } from 'src/redux/devices.api.ts';

import { Button } from './ui/Button';

const generalFormSchema = z.object({
  deviceName: z
    .string()
    .min(2, {
      message: 'Device name must be at least 2 characters.',
    })
    .max(30, {
      message: 'Device name must not be longer than 30 characters.',
    }),
});

type GeneralFormValues = z.infer<typeof generalFormSchema>;
export type BindGeneralFormValues = GeneralFormValues & { deviceId: string };

interface GeneralFormProps {
  device?: Device;
  status: QueryStatus;
  onSubmit: (value: BindGeneralFormValues) => void;
}

export const GeneralForm: FC<GeneralFormProps> = ({ status, device, onSubmit }) => {
  const defaultValues: Partial<GeneralFormValues> = {
    deviceName: device?.name ?? '',
  };

  const form = useForm<GeneralFormValues>({
    resolver: zodResolver(generalFormSchema),
    defaultValues,
  });

  const submit = (value: GeneralFormValues): void => {
    if (!device) return;
    onSubmit({ deviceId: device.id, deviceName: value.deviceName });
  };

  useEffect(() => {
    form.setValue('deviceName', device?.name ?? '');
  }, [form, device?.name]);

  return (
    <FormProvider {...form}>
      {}
      <form onSubmit={form.handleSubmit(submit)} className="space-y-8">
        <FormField
          control={form.control}
          name="deviceName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Device name</FormLabel>
              <FormControl>
                <Input placeholder="Device name" {...field} />
              </FormControl>
              <FormDescription>This name will be displayed on home page and device page.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center space-x-2">
          <Button type="submit">Update device</Button>
          {status === QueryStatus.pending && <Loader2 size={24} className="mr-2 animate-spin" />}
          {status === QueryStatus.fulfilled && <FormDescription>All changes was saved</FormDescription>}
        </div>
      </form>
    </FormProvider>
  );
};
