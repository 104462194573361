import { FC, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useGetDeviceQuery } from 'src/redux/devices.api.ts';
import { ApiError } from 'src/redux/types.ts';
import { cn } from 'src/lib/utils.ts';

import { AppHeader } from './AppHeader.tsx';
import { HeaderLink } from './HeaderLink.tsx';
import { Button } from './ui/Button.tsx';
import { Separator } from './ui/Separator.tsx';
import { SidebarNavItem } from './ui/SidebarNavItem.tsx';

export const SettingsLayout: FC = () => {
  const navigate = useNavigate();
  const { device_id } = useParams<{ device_id: string }>();
  const { data: device, error } = useGetDeviceQuery(String(device_id));

  useEffect(() => {
    if (error && (error as ApiError).status === 404) navigate('/404');
  }, [error, navigate]);

  const openLink = (href: string): void => {
    if (!device) return;
    navigate(`/devices/${device.id}/settings/${href}`);
  };

  return (
    <main>
      <AppHeader>
        <HeaderLink to="/">Home</HeaderLink>
      </AppHeader>

      <section className="container flex max-w-[960px] flex-col px-4 lg:px-0 2xl:max-w-[1400px]">
        <div className="py-6">
          <Button variant="link" className="w-fit pl-0 font-normal text-gray-500" onClick={() => navigate(-1)}>
            <ArrowLeft size={16} className="pr-0.5" />
            Go back
          </Button>
        </div>
        <div className="flex flex-col-reverse items-start justify-between gap-4 md:flex-row md:items-center md:gap-2 md:space-y-6">
          <div className="space-y-0.5">
            <h2 className="pb-2 text-4xl font-semibold tracking-tight transition-colors first:mt-0">Settings</h2>
            <p className="text-xl text-muted-foreground">Manage your device settings and set up preferences.</p>
          </div>

          <div className="flex items-end">
            <h2 className="text-3xl font-normal tracking-tight text-slate-900">{device?.name ?? ''}</h2>
            <h2 className="text-3xl font-normal tracking-tight text-slate-900">&nbsp;{device?.id && '/'}&nbsp;</h2>
          </div>
        </div>

        <Separator className="my-6" />

        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="lg:w-1/5">
            <nav className={cn('flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1')}>
              <SidebarNavItem title="General" href="general" onOpenLink={openLink} />
            </nav>
          </aside>
          <div className="flex-1 lg:max-w-2xl">
            <Outlet />
          </div>
        </div>
      </section>
    </main>
  );
};
