import { FC } from 'react';
import { Link } from 'react-router-dom';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './ui/DropdownMenu.tsx';
import { Avatar, AvatarFallback, AvatarImage } from './ui/Avatar.tsx';

type UserDropdownProps = {
  user: {
    name: string;
    email: string;
    picture: string;
  };
};

export const UserDropdown: FC<UserDropdownProps> = ({ user }) => (
  <DropdownMenu>
    <DropdownMenuTrigger className="flex items-center gap-2 outline-none">
      <span className="font-medium">{user.name}</span>
      <Avatar className="max-h-[36px] max-w-[36px]">
        <AvatarImage src={user.picture} alt={user.name} />
        <AvatarFallback>{user.name.charAt(0)}</AvatarFallback>
      </Avatar>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <div className="flex items-center gap-2 px-4 py-2 ">
        <Avatar className="max-h-[36px] max-w-[36px]">
          <AvatarImage src={user.picture} alt={user.name} />
          <AvatarFallback>{user.name.charAt(0)}</AvatarFallback>
        </Avatar>
        <div className="text-sm">
          <p className="font-medium">{user.name}</p>
          <p className="text-xs text-muted-foreground">{user.email}</p>
        </div>
      </div>
      <DropdownMenuItem asChild>
        <Link to="/sign-out">Sign out</Link>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);
