import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './baseQuery.ts';

export interface TokenResponse {
  created_at: string;
  expires_at: string;
  device_name: string;
  token: string;
  used: boolean;
  user_id: string;
}

const tokensApi = createApi({
  reducerPath: 'tokens',
  tagTypes: ['Tokens', 'Token'],
  baseQuery,
  endpoints: (builder) => ({
    createDeviceToken: builder.mutation<TokenResponse, { deviceName: string }>({
      query: ({ deviceName }) => ({
        url: '/auth/tokens',
        method: 'POST',
        body: { device_name: deviceName },
      }),
      invalidatesTags: ['Token'],
    }),
  }),
});

export const { useCreateDeviceTokenMutation } = tokensApi;

export default tokensApi;
