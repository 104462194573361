import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';

import { RootState } from './store';
import { DeviceId } from './types.ts';
import { Device } from './devices.api.ts';

interface SseState {
  isConnected: boolean;
  onlineDevices: DeviceId[];
  deviceRegistered: Device | null;
}

const initialState: SseState = {
  isConnected: false,
  onlineDevices: [],
  deviceRegistered: null,
};

export const sseSlice = createSlice({
  name: 'sse',
  initialState,
  reducers: {
    connectToSse: (state) => {
      state.isConnected = true;
      state.onlineDevices = [];
    },

    disconnectSse: (state) => {
      state.isConnected = false;
      state.onlineDevices = [];
    },

    receivedSseConnected: (state) => {
      state.isConnected = true;
    },

    receivedSseDisconnected: (state) => {
      state.isConnected = false;
      state.onlineDevices = [];
    },

    receivedSseError: (state) => {
      state.isConnected = false;
    },

    receivedDevicesOnline: (state, action: PayloadAction<string[]>) => {
      state.onlineDevices = uniq(action.payload);
    },

    receivedDeviceOnline: (state, action: PayloadAction<string>) => {
      state.onlineDevices = uniq([...state.onlineDevices, action.payload]);
    },

    receivedDeviceOffline: (state, action: PayloadAction<string>) => {
      state.onlineDevices = state.onlineDevices.filter((deviceId) => deviceId !== action.payload);
    },

    receivedDeviceRegistered: (state, action: PayloadAction<Device>) => {
      state.deviceRegistered = action.payload;
    },

    clearSseEvents: (state) => {
      state.onlineDevices = [];
    },

    clearDeviceRegistered: (state) => {
      state.deviceRegistered = null;
    },
  },
});

export const {
  connectToSse,
  disconnectSse,
  receivedSseConnected,
  receivedSseDisconnected,
  receivedSseError,
  receivedDevicesOnline,
  receivedDeviceOnline,
  receivedDeviceOffline,
  receivedDeviceRegistered,
  clearDeviceRegistered,
  clearSseEvents,
} = sseSlice.actions;

export const selectIsSseConnected = (state: RootState) => state.sse.isConnected;
export const selectOnlineDevices = (state: RootState) => state.sse.onlineDevices;
export const selectDeviceRegistered = (state: RootState) => state.sse.deviceRegistered;

export default sseSlice.reducer;
