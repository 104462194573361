import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError() as Response;

  console.error(error);

  return (
    <div className="flex h-screen flex-col items-center justify-center text-center" id="error-page">
      <div>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
      <div>{error.statusText}</div>
    </div>
  );
}
