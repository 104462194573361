import { SignalIcon, SignalLowIcon, SignalHighIcon, SignalZeroIcon, SignalMediumIcon, LucideIcon } from 'lucide-react';
import { forwardRef } from 'react';

type Percent = number;

type Props = {
  label?: string;
  strength: Percent;
};

const strengthToIconMap: Record<string, LucideIcon> = {
  zero: SignalZeroIcon,
  low: SignalLowIcon,
  medium: SignalMediumIcon,
  high: SignalHighIcon,
  full: SignalIcon,
};

export const SignalIndicator = forwardRef<HTMLDivElement, Props>(({ label, strength }, ref) => {
  let iconKey: string;

  if (strength <= 0) {
    iconKey = 'zero';
  } else if (strength > 0 && strength <= 25) {
    iconKey = 'low';
  } else if (strength > 25 && strength <= 50) {
    iconKey = 'medium';
  } else if (strength > 50 && strength <= 75) {
    iconKey = 'high';
  } else {
    iconKey = 'full';
  }

  const SignalStrengthIcon = strengthToIconMap[iconKey];

  return (
    <div ref={ref} className="relative p-1.5">
      <div className="text-shadow-md absolute right-5 top-0 whitespace-nowrap stroke-black text-xs text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
        {label?.toUpperCase()}
      </div>
      <SignalStrengthIcon size="24px" className="text-white drop-shadow-[-1px_1px_0px_rgba(0,0,0,0.25)]" />
    </div>
  );
});

SignalIndicator.displayName = 'SignalIndicator';
