import { RouterIcon } from 'lucide-react';

import { useAppSelector } from '../redux/hooks.ts';
import { selectIsModemConnected, selectModemErrorMessage, selectModemInfo } from '../redux/ms.slice.ts';

export const ModemStatusItem = () => {
  const isModemConnected = useAppSelector(selectIsModemConnected);
  const modemErrorMessage = useAppSelector(selectModemErrorMessage);
  const modemInfo = useAppSelector(selectModemInfo);

  if (!isModemConnected) return null;

  return (
    <div className="flex items-center gap-3">
      <RouterIcon size={32} />
      <div>
        <div>4G Modem: {isModemConnected ? 'Connected' : 'Disconnected'}</div>
        {modemErrorMessage && (
          <div className="max-w-sm text-sm opacity-80">
            {modemErrorMessage ??
              (modemInfo && `${modemInfo?.access_technologies} ${modemInfo?.m3gpp_operator_name} ${modemInfo?.state}`)}
          </div>
        )}
      </div>
    </div>
  );
};
