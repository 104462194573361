import { PayloadAction, createAction, createSlice, createSelector } from '@reduxjs/toolkit';

import { Meter } from 'src/types';
import type { RTPStreamStats } from 'src/lib/webrtc.ts';

import { RootState } from './store.ts';
import { calculateVideoQuality, determineVideoQuality } from './utils.ts';

interface WebRTCState {
  connectionState: RTCPeerConnectionState;
  stats: RTPStreamStats | null;
  track: MediaStreamTrack | null;
  latency: number | null;
  travelDistance: Meter | null;
  onlineClientsCount: number | null;
  terminalChannel: RTCDataChannel | null;
}

const initialState: WebRTCState = {
  connectionState: 'new',
  stats: null,
  track: null,
  latency: null,
  travelDistance: null,
  onlineClientsCount: null,
  terminalChannel: null,
};

export const webrtcSlice = createSlice({
  name: 'webrtc',
  initialState,
  reducers: {
    receivedConnectionState: (state: WebRTCState, action: PayloadAction<RTCPeerConnectionState>) => {
      state.connectionState = action.payload;
    },

    receivedLatency: (state, action: PayloadAction<number | null>) => {
      state.latency = action.payload;
    },

    newStats: (state, action: PayloadAction<RTPStreamStats>) => {
      state.stats = action.payload;
    },

    receivedNewTrack: (state, action: PayloadAction<MediaStreamTrack>) => {
      state.track = action.payload;
    },

    receivedTerminalChannel: (state, action: PayloadAction<RTCDataChannel>) => {
      state.terminalChannel = action.payload;
    },

    receivedOnlineClientsCount: (state, { payload }: PayloadAction<number>) => {
      state.onlineClientsCount = payload;
    },

    disconnect: (state) => {
      state.stats = null;
    },
  },
});

export const { disconnect, newStats, receivedNewTrack, receivedLatency } = webrtcSlice.actions;

export const receivedIceCandidate = createAction<RTCIceCandidateInit | undefined>('webrtc/receivedIceCandidate');
export const startPeerConnection = createAction<{ iceRestart?: boolean } | undefined>('webrtc/startPeerConnection');
export const restartWebRTCSession = createAction('webrtc/restartWebRTCSession');
export const acceptOffer = createAction<RTCSessionDescriptionInit>('webrtc/acceptOffer');
export const createOffer = createAction('webrtc/createOffer');
export const negotiationNeeded = createAction('webrtc/negotiationNeeded');
export const offerCreated = createAction<RTCSessionDescriptionInit>('webrtc/offerCreated');
export const answerCreated = createAction<RTCSessionDescriptionInit>('webrtc/answerCreated');
export const disconnected = createAction('webrtc/disconnected');
export const acceptAnswer = createAction<RTCSessionDescriptionInit>('webrtc/acceptAnswer');
export const setIceCandidate = createAction<RTCIceCandidateInit>('webrtc/setIceCandidate');
export const receivedConnectionState = createAction<RTCPeerConnectionState>('webrtc/receivedConnectionState');
export const receivedTerminalChannel = createAction<RTCDataChannel>('webrtc/receivedTerminalChannel');

export const selectWebrtcTrack = (state: RootState) => state.webrtc.track;
export const selectWebrtcStats = (state: RootState) => state.webrtc.stats;
export const selectWebrtcConnectionState = (state: RootState) => state.webrtc.connectionState;

export const selectVideoQuality = createSelector(
  (state: RootState) => state.webrtc.stats,
  (stats) => {
    if (!stats) return null;
    return {
      percent: calculateVideoQuality(stats),
      label: determineVideoQuality(stats),
    };
  },
);
