import './index.css';

import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import { App } from './App.tsx';
import store from './redux/store.ts';
import { ThemeProvider } from './components/ThemeProvider.tsx';
import { PostHogProvider } from 'posthog-js/react';
import { POSTHOG_HOST, POSTHOG_KEY } from './env.ts';

const rootElement = document.querySelector('#root')!;
const root = createRoot(rootElement);

const options = {
  api_host: POSTHOG_HOST,
};

function render(AppComponent: typeof App) {
  root.render(
    <ThemeProvider>
      <PostHogProvider apiKey={POSTHOG_KEY} options={options}>
        <ReduxProvider store={store}>
          <AppComponent />
        </ReduxProvider>
      </PostHogProvider>
    </ThemeProvider>,
  );
}

render(App);

if (import.meta.hot) {
  import.meta.hot.accept('./App.tsx', () => {
    // @ts-expect-error require not found
    // eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-unsafe-call
    const NextApp = require('./App.tsx').App;

    render(NextApp);
  });
}
