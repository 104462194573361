import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {
  Camera,
  CameraValues,
  InterfaceInfo,
  PiEvent,
  PiRtspDialErrorMessage,
  PiStatusMessage,
  SignalQuality,
} from 'src/lib/messages';

import { RootState } from './store.ts';
import { addOrUpdateLog } from './utils.ts';
import { Log, LogType } from './types.ts';

type ToastMessage = {
  label: string;
  description: string;
};

type ModemInfo = {
  access_technologies: string;
  m3gpp_operator_code: string;
  m3gpp_operator_name: string;
  m3gpp_registration_state: string;
  signal_quality: number;
  state: string;
};

interface MsState {
  isDeviceConnected: boolean;
  isWebrtcPeerConnected: boolean;
  isWebsocketConnected: boolean;
  isMavlinkConnected: boolean;
  isCameraConnected: boolean;
  mavlinkErrorMessage: string | null;
  isRtspConnected: null | boolean;
  rtspErrorMessage: string | null;
  isModemConnected: boolean;
  modemErrorMessage: string | null;
  logs: Log[];
  cameras: Record<Camera['id'], Camera>;
  modemInfo: ModemInfo | null;
  deviceId: string | null;
  websocketConnectionRetryCount: number;
  selectedCamera: Camera | null;
  toastMessage: ToastMessage | null;
  networkInterfaces: InterfaceInfo[] | null;
}

const initialState: MsState = {
  websocketConnectionRetryCount: 0,
  isDeviceConnected: false,
  isWebrtcPeerConnected: false,
  isCameraConnected: false,
  isMavlinkConnected: false,
  isModemConnected: false,
  modemErrorMessage: null,
  isWebsocketConnected: false,
  mavlinkErrorMessage: null,
  isRtspConnected: null,
  rtspErrorMessage: null,
  cameras: {},
  logs: [],
  modemInfo: null,
  deviceId: null,
  selectedCamera: null,
  toastMessage: null,
  networkInterfaces: null,
};

export const msSlice = createSlice({
  name: 'ms',
  initialState,
  reducers: {
    connectToWebsocket: (state, action: PayloadAction<string>) => {
      const deviceId = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['info', 'websocket', `connecting to websocket...`]);
      state.deviceId = deviceId;
    },

    receivedWebsocketConnected: (state) => {
      state.isWebsocketConnected = true;
      state.logs = addOrUpdateLog(state.logs, ['success', 'websocket', `connected to websocket`]);
      state.websocketConnectionRetryCount = 0;
    },

    receivedPiStatusAck: (state, { payload }: PayloadAction<PiStatusMessage['payload']>) => {
      if (payload.mavlink) {
        state.logs = addOrUpdateLog(state.logs, [
          payload.mavlink.type as LogType,
          'mavlink',
          payload.mavlink?.payload as string,
        ]);
      }
      if (payload.rtsp.type && payload.rtsp?.payload) {
        state.logs = addOrUpdateLog(state.logs, [
          payload.rtsp.type as LogType,
          'rtsp',
          payload.rtsp?.payload as string,
        ]);
        state.isRtspConnected = true;
      }

      if (payload.rtsp.type == 'error') {
        state.isRtspConnected = false;
        state.rtspErrorMessage = payload.rtsp.payload as string;
      }

      if (payload.mavlink.type == 'error') {
        state.isMavlinkConnected = false;
        state.mavlinkErrorMessage = payload.mavlink.payload as string;
      }

      if (payload.modem.type == 'error') {
        state.isModemConnected = false;
        state.modemErrorMessage = payload.modem.payload as string;
      }

      if (payload.modem.type == 'info' || payload.modem.type == 'success') {
        state.isModemConnected = true;
        state.modemErrorMessage = null;
      }

      if (isString(payload.webrtc?.payload)) {
        state.logs = addOrUpdateLog(state.logs, [payload.webrtc.type as LogType, 'webrtc', payload.webrtc?.payload]);
      }
      if (isString(payload.modem?.payload)) {
        state.logs = addOrUpdateLog(state.logs, [payload.modem.type as LogType, 'modem', payload?.modem?.payload]);
      }
    },

    receivedPiConnected: (state) => {
      state.isDeviceConnected = true;
      state.logs = addOrUpdateLog(state.logs, ['success', 'pi', 'device connected']);
    },

    receivedPiDisconnected: (state) => {
      state.isDeviceConnected = false;
      state.logs = addOrUpdateLog(state.logs, ['warning', 'pi', 'device disconnected']);
    },

    receivedWebRtcPeerConnected: (state) => {
      state.isWebrtcPeerConnected = true;
      state.logs = addOrUpdateLog(state.logs, ['success', 'webrtc', "pi's peer connected"]);
    },

    receivedRtspDialError: (state, { payload }: PayloadAction<PiRtspDialErrorMessage['payload']>) => {
      state.isRtspConnected = false;
      state.rtspErrorMessage = payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', payload]);
    },

    receivedWebRtcPeerDisconnected: (state) => {
      state.isWebrtcPeerConnected = false;
      state.logs = addOrUpdateLog(state.logs, ['warning', 'webrtc', 'peer disconnected']);
    },

    receivedPiModemSignalQuality: (state, { payload }: PayloadAction<SignalQuality>) => {
      state.isModemConnected = true;
      state.modemErrorMessage = null;
      state.logs = addOrUpdateLog(state.logs, ['info', 'modem', `signal quality: ${payload}`]);
    },

    receiveWebsocketClosedUnexpectedly: (state) => {
      state.isWebsocketConnected = false;
      state.logs = addOrUpdateLog(state.logs, [
        'error',
        'websocket',
        'connection closed unexpectedly. Retries left: ' + (4 - state.websocketConnectionRetryCount),
      ]);
      state.websocketConnectionRetryCount = state.websocketConnectionRetryCount + 1;
    },

    receivedWebsocketMaxRetriesReached: (state) => {
      state.logs = addOrUpdateLog(state.logs, [
        'error',
        'websocket',
        'max retries reached. Could not establish a WebSocket connection.',
      ]);
    },

    receiveWebsocketDisconnected: (state) => {
      state.isWebsocketConnected = false;
      state.logs = addOrUpdateLog(state.logs, ['info', 'websocket', `disconnected from ${state.deviceId}`]);
    },

    receivedPiAnswer: (state, _: PayloadAction<RTCSessionDescriptionInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'webrtc', 'received answer from pi']);
    },

    receivedPiAnswerAck: (state, _: PayloadAction<RTCSessionDescriptionInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'webrtc', 'received answer ack']);
    },

    receivedPiWebrtcOffer: (state, _: PayloadAction<RTCSessionDescriptionInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'webrtc', 'received offer from pi']);
    },

    receivedPiOfferAck: (state, _: PayloadAction<RTCSessionDescriptionInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'webrtc', 'received offer ack']);
    },

    receivedPiIceCandidate: (state, _: PayloadAction<RTCIceCandidateInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'webrtc', 'received ICE candidate from pi']);
    },

    receivedPiIcecandidateAck: (state, _: PayloadAction<RTCIceCandidateInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'webrtc', 'received ICE candidate ack from pi']);
    },

    receivedWebrtcSessionStarted: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'webrtc', 'pi started new session']);
    },

    receivedWebrtcIceConnected: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'webrtc', 'ICE connected']);
    },

    receivedWebrtcIceDisconnected: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['warning', 'webrtc', 'ICE disconnected']);
    },

    receivedPiModemConnected: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.modemErrorMessage = null;
        state.isModemConnected = true;
        state.logs = addOrUpdateLog(state.logs, ['info', 'modem', `connected: ${payload}`]);
      }
    },

    receivedPiModemInfo: (state, { payload }: PayloadAction<PiEvent<SignalQuality>>) => {
      if (payload.payload) {
        state.modemErrorMessage = null;
        state.isModemConnected = true;
        state.logs = addOrUpdateLog(state.logs, ['info', 'modem', `info: ${payload.payload}`]);
      }
    },

    receivedRtspConnected: (state, { payload }: PayloadAction<string>) => {
      state.isRtspConnected = true;
      state.rtspErrorMessage = null;
      state.logs = addOrUpdateLog(state.logs, ['success', 'rtsp', payload]);
    },

    receivedRtspStreamReady: (state, { payload }: PayloadAction<string>) => {
      state.logs = addOrUpdateLog(state.logs, ['success', 'rtsp', `stream ready: ${payload}`]);
    },

    receivedRtspDescribe: (state, { payload }: PayloadAction<string>) => {
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', `describe: ${payload}`]);
    },

    receivedPiRtspListenError: (state, { payload }: PayloadAction<string>) => {
      state.isRtspConnected = false;
      state.rtspErrorMessage = payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', payload]);
    },

    receivedRtspError: (state, { payload }: PayloadAction<string>) => {
      state.isRtspConnected = false;
      state.rtspErrorMessage = payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', payload]);
    },

    receivedPiRtspClientError: (state, { payload }: PayloadAction<string>) => {
      state.isRtspConnected = false;
      state.rtspErrorMessage = payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', payload]);
    },

    receivedMavlinkConnected: (state, { payload }: PayloadAction<string>) => {
      state.isMavlinkConnected = true;
      state.mavlinkErrorMessage = null;
      state.logs = addOrUpdateLog(state.logs, ['info', 'mavlink', `connected: ${payload}`]);
    },

    receivedStartWebRTCSessionRequestAck: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['success', 'webrtc', 'pi started webrtc session']);
    },

    receivedRtspPublishError: (state, action: PayloadAction<string>) => {
      state.rtspErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', 'publish error: ' + action.payload]);
    },

    receivedRtspPacketLostError: (state, action: PayloadAction<string>) => {
      state.rtspErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', 'packet lost: ' + action.payload]);
    },

    receivedRtspDecodeError: (state, action: PayloadAction<string>) => {
      state.rtspErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', 'decode error: ' + action.payload]);
    },

    receivedPiWebRtcError: (state, action: PayloadAction<string>) => {
      state.logs = addOrUpdateLog(state.logs, ['error', 'webrtc', 'error: ' + action.payload]);
    },

    receivedPiMavlinkDialError: (state, action: PayloadAction<string>) => {
      state.isMavlinkConnected = false;
      state.mavlinkErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'mavlink', 'error: ' + action.payload]);
    },

    receivedPiModemConnectionError: (state, action: PayloadAction<string>) => {
      state.isModemConnected = false;
      state.modemErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'modem', 'error: ' + action.payload]);
    },

    receivedPiModemModemInfoAck: (state, action: PayloadAction<ModemInfo>) => {
      state.modemInfo = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['success', 'modem', 'received modem info']);
    },

    receivedPiWebrtcOfferError: (state, action: PayloadAction<string>) => {
      state.toastMessage = { label: 'WebRTC error', description: action.payload };
      state.logs = addOrUpdateLog(state.logs, ['error', 'webrtc', action.payload]);
    },

    receivedGetCameraListAck: (state, action: PayloadAction<Camera[]>) => {
      state.cameras = keyBy(action.payload, 'id');
      if (isEmpty(action.payload)) {
        state.logs = addOrUpdateLog(state.logs, ['warning', 'camera', `no cameras connected`]);
      } else {
        state.logs = addOrUpdateLog(state.logs, [
          'info',
          'camera',
          `list: ${action.payload.map((camera) => camera.name).join(', ')}`,
        ]);
      }
    },

    receivedGetCameraListError: (state, action: PayloadAction<string>) => {
      state.toastMessage = { label: 'Unable to get available cameras', description: action.payload };
      state.logs = addOrUpdateLog(state.logs, ['error', 'camera', action.payload]);
    },

    receivedAddCameraAck: (state, action: PayloadAction<Camera>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'camera', `camera ${action.payload.name} added`]);
      state.cameras[action.payload.id] = action.payload;
    },

    receivedAddCameraError: (state, action: PayloadAction<string>) => {
      state.toastMessage = { label: 'Unable to add camera', description: action.payload };
      state.logs = addOrUpdateLog(state.logs, ['error', 'camera', action.payload]);
    },

    receivedUpdateCameraAck: (state, action: PayloadAction<Camera>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'camera', `camera ${action.payload.name} updated`]);
      state.cameras[action.payload.id] = action.payload;
    },

    receivedUpdateCameraError: (state, action: PayloadAction<string>) => {
      state.toastMessage = { label: 'Unable to update camera', description: action.payload };
      state.logs = addOrUpdateLog(state.logs, ['error', 'camera', action.payload]);
    },

    receivedRemoveCameraAck: (state, action: PayloadAction<string>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'camera', `camera ${action.payload} removed`]);
      state.cameras = omit(state.cameras, action.payload);
    },

    receivedSwitchCameraAck: (state, action: PayloadAction<Camera>) => {
      state.selectedCamera = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['info', 'camera', `camera ${action.payload.name} set`]);
    },

    receivedSwitchCameraError: (state, action: PayloadAction<string>) => {
      state.toastMessage = { label: 'Unable to switch camera', description: action.payload };
      state.logs = addOrUpdateLog(state.logs, ['error', 'camera', action.payload]);
    },

    receivedRemoveCameraError: (state, action: PayloadAction<string>) => {
      state.toastMessage = { label: 'Unable to remove camera', description: action.payload };
      state.logs = addOrUpdateLog(state.logs, ['error', 'camera', action.payload]);
    },

    receivedGetSelectedCameraAck: (state, action: PayloadAction<Camera>) => {
      state.selectedCamera = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['info', 'camera', `received selected camera ${action.payload.name}`]);
    },

    receivedGetSelectedCameraError: (state, action: PayloadAction<string>) => {
      state.toastMessage = { label: 'Unable to get selected camera', description: action.payload };
      state.isRtspConnected = false;
      state.rtspErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'camera', action.payload]);
    },

    receivedPiRtspDisconnectWarning: (state, action: PayloadAction<string>) => {
      state.isRtspConnected = false;
      state.rtspErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['info', 'rtsp', `disconnected ${action.payload}`]);
    },

    receivedGetNetworkInterfacesAck: (state, action: PayloadAction<InterfaceInfo[]>) => {
      state.networkInterfaces = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['info', 'network', 'received network interfaces']);
    },

    receivedRtspConnectFailError: (state, action: PayloadAction<string>) => {
      state.isRtspConnected = false;
      state.rtspErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['error', 'rtsp', action.payload]);
    },

    receivedWebrtcSessionStopWarning: (state, action: PayloadAction<string>) => {
      state.isWebrtcPeerConnected = false;
      state.logs = addOrUpdateLog(state.logs, ['warning', 'webrtc', action.payload]);
    },

    receivedRtspRedialError: (state, action: PayloadAction<string>) => {
      state.rtspErrorMessage = action.payload;
      state.logs = addOrUpdateLog(state.logs, ['warning', 'rtsp', action.payload]);
    },

    receivedPeerConnectingInfo: (state, action: PayloadAction<string>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'webrtc', action.payload]);
    },

    sendRtspDial: (state) => {
      state.rtspErrorMessage = 'sending rtsp dial request...';
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'sending rtsp dial request...']);
    },

    sendStartWebRtcSessionRequest: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'requesting webrtc session to start on pi']);
    },

    sendOffer: (state, _: PayloadAction<RTCSessionDescriptionInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'sending offer']);
    },

    sendGetCameraList: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'requesting camera list']);
    },

    sendGetSelectedCamera: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'requesting selected camera']);
    },

    sendGetNetworkInterfaces: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'requesting network interfaces']);
    },

    sendAddCamera: (state, _: PayloadAction<CameraValues>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'adding camera']);
    },

    sendUpdateCamera: (state, _: PayloadAction<Camera>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'updating camera']);
    },

    sendRemoveCamera: (state, _: PayloadAction<Camera>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'removing camera']);
    },

    sendSwitchCamera: (state, action: PayloadAction<Camera>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', `switching camera to ${action.payload.name}`]);
    },

    sendAnswer: (state, _: PayloadAction<RTCSessionDescriptionInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'sending answer']);
    },

    sendIceCandidate: (state, _: PayloadAction<RTCIceCandidateInit>) => {
      state.logs = addOrUpdateLog(state.logs, ['debug', 'web', 'sending ICE candidate']);
    },

    sendReboot: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'sending reboot']);
    },

    sendPiStatusRequest: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'sending pi:get_status request']);
    },

    sendPiModemInfoRequest: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'sending pi:get_modem_info request']);
    },

    disconnectWebsocket: (state) => {
      state.logs = addOrUpdateLog(state.logs, ['info', 'web', 'close websocket connection']);
    },

    clearToastMessage: (state) => {
      state.toastMessage = null;
    },

    clear: () => initialState,
  },
});

export const {
  connectToWebsocket,
  receivedWebsocketConnected,
  receiveWebsocketClosedUnexpectedly,
  receivedWebsocketMaxRetriesReached,
  receiveWebsocketDisconnected,
  receivedPiStatusAck,
  receivedPiWebrtcOfferError,
  receivedWebRtcPeerConnected,
  receivedWebRtcPeerDisconnected,
  receivedPiModemSignalQuality,
  receivedPiConnected,
  receivedPiDisconnected,
  receivedRtspError,
  receivedRtspDialError,
  receivedPiAnswer,
  receivedPiWebrtcOffer,
  receivedWebrtcSessionStarted,
  receivedPiIceCandidate,
  sendOffer,
  receivedWebrtcIceConnected,
  receivedWebrtcIceDisconnected,
  receivedPiOfferAck,
  receivedPiAnswerAck,
  receivedPiIcecandidateAck,
  receivedPiWebRtcError,
  receivedPiMavlinkDialError,
  receivedPiModemConnectionError,
  receivedGetCameraListAck,
  receivedAddCameraAck,
  receivedAddCameraError,
  receivedUpdateCameraAck,
  receivedRemoveCameraAck,
  receivedGetCameraListError,
  receivedUpdateCameraError,
  receivedRemoveCameraError,
  receivedSwitchCameraAck,
  receivedSwitchCameraError,
  receivedGetSelectedCameraAck,
  receivedGetSelectedCameraError,
  receivedPiRtspDisconnectWarning,
  receivedGetNetworkInterfacesAck,
  receivedRtspConnectFailError,
  receivedWebrtcSessionStopWarning,
  receivedRtspRedialError,
  receivedPeerConnectingInfo,
  sendRtspDial,
  sendGetNetworkInterfaces,
  sendAnswer,
  sendIceCandidate,
  sendReboot,
  sendPiStatusRequest,
  sendPiModemInfoRequest,
  sendGetCameraList,
  sendAddCamera,
  sendUpdateCamera,
  sendRemoveCamera,
  sendSwitchCamera,
  sendGetSelectedCamera,
  sendStartWebRtcSessionRequest,
  receivedStartWebRTCSessionRequestAck,
  receivedPiModemConnected,
  receivedPiRtspClientError,
  receivedPiRtspListenError,
  receivedPiModemInfo,
  receivedPiModemModemInfoAck,
  receivedRtspConnected,
  receivedRtspStreamReady,
  receivedRtspDescribe,
  receivedMavlinkConnected,
  receivedRtspPublishError,
  receivedRtspPacketLostError,
  receivedRtspDecodeError,
  disconnectWebsocket,
  clearToastMessage,
  clear,
} = msSlice.actions;

export const selectModemInfo = (state: RootState) => state.ms.modemInfo;
export const selectIsDeviceConnected = (state: RootState) => state.ms.isDeviceConnected;
export const selectIsWebrtcPeerConnected = (state: RootState) => state.ms.isWebrtcPeerConnected;
export const selectMavlinkErrorMessage = (state: RootState) => state.ms.mavlinkErrorMessage;
export const selectIsMavlinkConnected = (state: RootState) => state.ms.isMavlinkConnected;
export const selectRtspErrorMessage = (state: RootState) => state.ms.rtspErrorMessage;
export const selectIsRtspConnected = (state: RootState) => state.ms.isRtspConnected;
export const selectModemErrorMessage = (state: RootState) => state.ms.modemErrorMessage;
export const selectIsModemConnected = (state: RootState) => state.ms.isModemConnected;
export const selectLogs = (state: RootState) => state.ms.logs;
export const selectDeviceId = (state: RootState) => state.ms.deviceId;
export const selectIsWebsocketConnected = (state: RootState) => state.ms.isWebsocketConnected;
export const selectWebsocketConnectionRetryCount = (state: RootState) => state.ms.websocketConnectionRetryCount;

// Memoize selectCameraList to avoid returning a new array reference each time
export const selectCameraList = createSelector(
  (state: RootState) => state.ms.cameras,
  (cameras) => Object.values(cameras),
);

export const selectToastMessage = (state: RootState) => state.ms.toastMessage;

export const selectSelectedCamera = (state: RootState) => state.ms.selectedCamera;

// Memoize selectNetworkInterfaceNames to avoid returning a new array reference each time
export const selectNetworkInterfaceNames = createSelector(
  (state: RootState) => state.ms.networkInterfaces,
  (interfaces) => interfaces?.map((n) => n.name),
);
