import type { RTPStreamStats } from '../lib/webrtc.ts';

import { Log, LogType } from './types.ts';

export function getLogLevel(messageName: string): LogType {
  if (messageName.endsWith('_info')) {
    return 'info';
  } else if (messageName.endsWith('_warning')) {
    return 'warning';
  } else if (messageName.endsWith('_error')) {
    return 'error';
  } else if (messageName.endsWith('_success')) {
    return 'success';
  } else {
    throw new Error(`missing message suffix, ${messageName}`);
  }
}

export const addOrUpdateLog = (logs: Log[], newLog: Log): Log[] => {
  const lastLogIndex = logs.length - 1;
  const lastLog = logs[lastLogIndex];

  if (
    lastLog &&
    lastLog[0] === newLog[0] && // Same type
    lastLog[1] === newLog[1] && // Same category
    lastLog[2] === newLog[2] // Same message
  ) {
    const count = lastLog[3] ? lastLog[3] + 1 : 2; // Increment count
    logs[lastLogIndex] = [...lastLog.slice(0, 3), count] as unknown as Log; // Ensure type safety by converting to unknown first
    return logs;
  } else {
    return [...logs, newLog]; // Add new log
  }
};

export function calculateVideoQuality(stats: RTPStreamStats): number {
  const frameDropRate = stats.framesReceived ? ((stats?.framesDropped ?? 0) / stats.framesReceived) * 100 : 0;
  const packetLossRate =
    (stats.packetsReceived ?? 0) + (stats.packetsLost ?? 0) !== 0
      ? ((stats.packetsLost ?? 0) / ((stats.packetsReceived ?? 0) + (stats.packetsLost ?? 0))) * 100
      : 0;
  const freezeImpact = (stats.freezeCount ?? 0) * 5;
  const jitterImpact = Math.min(10 * (stats.jitter ?? 0), 10);

  // Aggregate Video Quality Score
  const videoQuality = 100 - (frameDropRate + packetLossRate + freezeImpact + jitterImpact);

  return Math.max(videoQuality, 0); // Ensure that quality doesn't drop below 0%
}

export type VideoQuality = 'SD' | 'HD' | 'FHD';

export function determineVideoQuality(stats: RTPStreamStats): VideoQuality | undefined {
  const width = stats.frameWidth ?? 0;
  const height = stats.frameHeight ?? 0;

  if (width >= 1920 && height >= 1080) {
    return 'FHD';
  } else if (width >= 1280 && height >= 720) {
    return 'HD';
  } else if (width >= 640 && height >= 480) {
    return 'SD';
  } else {
    return undefined;
  }
}
