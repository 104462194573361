import { FC } from 'react';

import { Button } from './ui/Button';
import { ConfirmDeleteDeviceDialog } from './ConfirmDeleteDeviceDialog'; // Import your dialog component

interface DeleteDeviceProps {
  deviceName?: string;
  onDelete: () => void;
}

export const DeleteDeviceZone: FC<DeleteDeviceProps> = ({ deviceName, onDelete }) => {
  return (
    <div className="pt-4">
      <h3 className="mb-3 text-lg font-medium">Danger Zone</h3>

      <div className="flex-row items-center justify-between rounded-lg border border-destructive/20 p-4 md:flex">
        <div className="space-y-0.5 pb-4 md:pb-0">
          <p className="text-base">Delete this device</p>
          <p className="text-sm text-muted-foreground">To stop using this device, delete it from your account.</p>
        </div>
        <ConfirmDeleteDeviceDialog deviceName={deviceName} onConfirm={onDelete}>
          <Button variant="outline" className="text-destructive">
            Delete device
          </Button>
        </ConfirmDeleteDeviceDialog>
      </div>
    </div>
  );
};
