import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { PlusCircle, Info } from 'lucide-react';

import { Skeleton } from 'src/components/ui/Skeleton.tsx';
import { useGetDevicesQuery } from 'src/redux/devices.api.ts';
import { useAppDispatch, useAppSelector } from '../redux/hooks.ts';
import { connectToSse, disconnectSse, selectOnlineDevices } from '../redux/sse.slice.ts';
import { AppHeader } from './AppHeader.tsx';
import { DeviceCard } from './DeviceCard.tsx';
import { Button } from './ui/Button.tsx';
import { Alert, AlertDescription } from './ui/Alert.tsx';

export function IndexPage() {
  const navigate = useNavigate();
  const { isLoading, data, isError } = useGetDevicesQuery(null);
  const onlineDevices = useAppSelector(selectOnlineDevices);
  const isEmpty = data?.length === 0 && !isLoading;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      toast.error('Error fetching devices');
    }
  }, [isError]);

  useEffect(() => {
    dispatch(connectToSse());
    return () => {
      dispatch(disconnectSse());
    };
  }, [dispatch]);

  return (
    <div className="relative min-h-screen flex flex-col bg-background/95">
      <Helmet>
        <title>AirCast Dashboard</title>
      </Helmet>
      <AppHeader />

      <main className="container flex-1 py-6">
        <div className="space-y-6">
          {/* Header Section */}
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="space-y-1">
              <h1 className="text-2xl font-semibold tracking-tight">Devices</h1>
              <p className="text-sm text-muted-foreground">Manage and connect to your AirCast devices</p>
            </div>

            {!isEmpty && (
              <Button asChild className="gap-2 whitespace-nowrap">
                <Link to="https://aircast.one/docs/aircast/installation" className="text-inherit">
                  <PlusCircle className="h-4 w-4" />
                  Add Device
                </Link>
              </Button>
            )}
          </div>

          {/* Help Alert */}
          <Alert className="bg-muted/50">
            <AlertDescription className="flex flex-row gap-3 items-center">
              <Info className="h-4 w-4" />
              <div>Connect to your devices to start streaming or click the settings icon to configure them.</div>
            </AlertDescription>
          </Alert>

          {/* Devices List */}
          <div className="space-y-2">
            {isLoading && (
              <>
                <Skeleton className="h-[72px] w-full rounded-lg" />
                <Skeleton className="h-[72px] w-full rounded-lg" />
                <Skeleton className="h-[72px] w-full rounded-lg" />
              </>
            )}

            {isEmpty && (
              <div className="rounded-lg border border-dashed p-8 text-center">
                <div className="mx-auto w-fit rounded-full bg-primary/10 p-3">
                  <PlusCircle className="h-6 w-6 text-primary" />
                </div>
                <h2 className="mt-4 font-medium">No Devices Found</h2>
                <p className="mt-2 text-sm text-muted-foreground">Get started by adding your first device</p>
                <Button asChild className="mt-4">
                  <Link to="https://aircast.one/docs/aircast/installation">Add Your First Device</Link>
                </Button>
              </div>
            )}

            {data?.map((device) => (
              <DeviceCard
                key={device.id}
                isOnline={onlineDevices.includes(device.id)}
                device={device}
                onConnect={() => navigate(`/devices/${device.id}`)}
                onOpenSettings={() => navigate(`/devices/${device.id}/settings/general`)}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
