import { Helmet } from 'react-helmet';

import { AppHeader } from './AppHeader';
import { HeaderLink } from './HeaderLink';

export function NotFoundPage() {
  return (
    <main className="relative flex h-screen flex-col">
      <AppHeader>
        <HeaderLink to="/">Home</HeaderLink>
      </AppHeader>
      <Helmet>
        <title>Opps. Page not found</title>
      </Helmet>
      <section className="container flex grow flex-col items-center justify-center px-0">
        <h3 className="scroll-m-20 text-2xl tracking-tight">Opps! This page does not exist</h3>
      </section>
    </main>
  );
}
