import capitalize from 'lodash/capitalize';

import { selectWebrtcConnectionState } from '../redux/webrtc.slice.ts';
import { useAppSelector } from '../redux/hooks.ts';

import { WEBRTCIcon } from './icons/WebRTCIcon.tsx';

export const WebRtcStatusItem = () => {
  const webrtcConnectionState = useAppSelector(selectWebrtcConnectionState);

  return (
    <div className="flex items-center gap-3">
      <WEBRTCIcon className="h-8 text-white" />
      <div className="grow">
        <div>WebRTC state: {capitalize(webrtcConnectionState)}</div>
      </div>
    </div>
  );
};
