import { RadioTowerIcon } from 'lucide-react';

import { useAppSelector } from '../redux/hooks.ts';
import { selectIsMavlinkConnected, selectMavlinkErrorMessage } from '../redux/ms.slice.ts';

export const MavlinkStatusItem = () => {
  const isMavlinkConnected = useAppSelector(selectIsMavlinkConnected);
  const mavlinkErrorMessage = useAppSelector(selectMavlinkErrorMessage);

  if (!isMavlinkConnected && !mavlinkErrorMessage) {
    return null;
  }

  return (
    <div className="flex items-center gap-3">
      <RadioTowerIcon size={32} />
      <div>
        <div>Mavlink: {isMavlinkConnected ? 'Connected' : 'Disconnected'}</div>
        {mavlinkErrorMessage && <div className="max-w-sm text-sm opacity-80">{mavlinkErrorMessage}</div>}
      </div>
    </div>
  );
};
