import { AnyAction, Dispatch, Middleware, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';

import { API_URL } from '../env.ts';

import * as sseSlice from './sse.slice.ts';
import { DeviceId } from './types.ts';
import { Device } from './devices.api.ts';

let eventSource: EventSource | null = null;
let reconnectTimeout: number | null = null;
let reconnectAttempts = 0;
const maxReconnectAttempts = 3;

const connectToSse = (api: MiddlewareAPI<Dispatch<AnyAction>>) => {
  eventSource = new EventSource(`${API_URL}/auth/sse`, { withCredentials: true });

  eventSource.onopen = () => {
    reconnectAttempts = 0; // Reset the counter on successful connection
    api.dispatch(sseSlice.receivedSseConnected());
  };

  eventSource.onerror = (error) => {
    console.error('SSE connection error:', error);
    api.dispatch(sseSlice.receivedSseError());

    // Attempt to reconnect after a delay, limited to maxReconnectAttempts
    if (eventSource && eventSource.readyState === EventSource.CLOSED && reconnectAttempts < maxReconnectAttempts) {
      reconnectAttempts++;
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      reconnectTimeout = setTimeout(() => connectToSse(api), 3000); // Reconnect after 3 seconds
    } else if (reconnectAttempts >= maxReconnectAttempts) {
      console.warn(`Max reconnection attempts (${maxReconnectAttempts}) reached. No further attempts will be made.`);
    }
  };

  eventSource.addEventListener('devices-online', (event: MessageEvent<string>) => {
    const devices = JSON.parse(event.data) as DeviceId[];
    api.dispatch(sseSlice.receivedDevicesOnline(devices));
  });

  eventSource.addEventListener('device-online', (event: MessageEvent<string>) => {
    api.dispatch(sseSlice.receivedDeviceOnline(JSON.parse(event.data) as DeviceId));
  });

  eventSource.addEventListener('device-offline', (event: MessageEvent<string>) => {
    api.dispatch(sseSlice.receivedDeviceOffline(JSON.parse(event.data) as DeviceId));
  });

  eventSource.addEventListener('device-registered', (event: MessageEvent<string>) => {
    api.dispatch(sseSlice.receivedDeviceRegistered(JSON.parse(event.data) as Device));
  });
};

const sseMiddleware: Middleware = (api) => (next) => (_action) => {
  const action = _action as PayloadAction<never>;
  const response = next(action);

  if (action.type === sseSlice.connectToSse.toString()) {
    connectToSse(api);
  }

  if (action.type === sseSlice.disconnectSse.toString()) {
    if (!eventSource) {
      return console.warn('Closing SSE connection that does not exist!');
    }
    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
    }
    eventSource.close();
    eventSource = null;
    reconnectAttempts = 0; // Reset the counter when explicitly disconnecting
    api.dispatch(sseSlice.receivedSseDisconnected());
  }

  return response;
};

export default sseMiddleware;
