import { Middleware, PayloadAction } from '@reduxjs/toolkit';

import PeerConnection from 'src/lib/PeerConnection.ts';
import { listenForStats, RTPStreamStats } from 'src/lib/webrtc.ts';

import { AppDispatch } from './store.ts';
import * as webrtcSlice from './webrtc.slice.ts';
import { negotiationNeeded } from './webrtc.slice.ts';

function initPeerConnection(dispatch: AppDispatch) {
  const peerConnection = new PeerConnection();

  peerConnection.onLatency((latency) => dispatch(webrtcSlice.receivedLatency(latency)));

  peerConnection.onIceCandidate((candidate) => {
    dispatch(webrtcSlice.receivedIceCandidate(candidate));
  });

  peerConnection.onTrack(({ track, peerConnection }) => {
    console.log('received track', track);
    dispatch(webrtcSlice.receivedNewTrack(track));
    listenForStats(peerConnection, track, (stats) =>
      dispatch(webrtcSlice.newStats(stats as unknown as RTPStreamStats)),
    );
  });

  peerConnection.onNegotiationNeeded(() => {
    dispatch(negotiationNeeded());
  });

  peerConnection.onSignalingStateChange((signalingState) =>
    console.info('peerConnection.signalingstate', signalingState),
  );

  peerConnection.onConnectionStateChange((connectionState) => {
    console.info('peerConnection.connectionState', connectionState);
    dispatch(webrtcSlice.receivedConnectionState(connectionState));
  });

  peerConnection.onIceConnectionStateChange((iceConnectionState) => {
    console.info('peerConnection.iceConnectionState', iceConnectionState);
    if (iceConnectionState === 'failed') {
      peerConnection.restartIce();
    }
  });

  peerConnection.onTerminalChannel((channel) => {
    dispatch(webrtcSlice.receivedTerminalChannel(channel));
  });

  return peerConnection;
}

let peerConnection: PeerConnection | null = null;

const webrtcMiddleware: Middleware = (api) => (next) => (_action) => {
  const action = _action as PayloadAction<never>;
  const response = next(action);

  if (action.type === webrtcSlice.startPeerConnection.toString()) {
    if (peerConnection) {
      peerConnection.close();
      peerConnection = null;
    }
    peerConnection = initPeerConnection(api.dispatch);
  }

  if (!peerConnection) return response;

  if (action.type === webrtcSlice.createOffer.toString()) {
    peerConnection
      .createOffer()
      .then((offer) => api.dispatch(webrtcSlice.offerCreated(offer)))
      .catch(console.error);
  }

  if (action.type === webrtcSlice.negotiationNeeded.toString()) {
    peerConnection
      .createOffer()
      .then((offer) => api.dispatch(webrtcSlice.offerCreated(offer)))
      .catch(console.error);
  }

  if (action.type === webrtcSlice.acceptOffer.toString()) {
    const remoteSDP: string = (response as { payload: string }).payload;
    const remoteDescription = new RTCSessionDescription({
      type: 'offer',
      sdp: remoteSDP,
    });
    peerConnection
      .createAnswer(remoteDescription)
      .then((answer) => api.dispatch(webrtcSlice.answerCreated(answer)))
      .catch(console.error);
  }

  if (action.type === webrtcSlice.acceptAnswer.toString()) {
    peerConnection.setRemoteDescription(action.payload).catch(console.error);
  }

  if (action.type === webrtcSlice.setIceCandidate.toString()) {
    peerConnection.addIceCandidate(action.payload).catch((err) => {
      console.error(err);
    });
  }

  if (action.type === webrtcSlice.disconnect.toString()) {
    peerConnection.close();
    peerConnection = null;
    api.dispatch(webrtcSlice.disconnected());
  }

  window.addEventListener('beforeunload', () => peerConnection?.close());

  return response;
};

export default webrtcMiddleware;
