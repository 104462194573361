import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TerminalState {
  output: string | null; // To store the terminal output
  command: string | null; // To store the last executed command
}

const initialState: TerminalState = {
  output: null, // Initialize with empty output
  command: null, // No command at the start
};

export const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    setTerminalOutput: (state, action: PayloadAction<string>) => {
      state.output = action.payload;
    },
    clearTerminalOutput: (state) => {
      state.output = null;
    },
    sendTerminalCommand: (state, action: PayloadAction<string>) => {
      state.command = action.payload;
    },
  },
});

export const { setTerminalOutput, sendTerminalCommand, clearTerminalOutput } = terminalSlice.actions;

export default terminalSlice.reducer;
