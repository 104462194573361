import { AppFooter } from './AppFooter.tsx';
import { AppHeader } from './AppHeader.tsx';
import { useState } from 'react';
import imagerJpg from '../assets/imager.jpg';
import { CardHeader, CardTitle, Card } from './ui/Card.tsx';
import { CodeSnippet } from './CodeShippet.tsx';
import { Progress } from './ui/Progress.tsx';
import { Check, Info, Power, Settings, Timer, Wifi } from 'lucide-react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/Accordion.tsx';

export function RegisterDevicePage() {
  const [activeStep, setActiveStep] = useState(1);
  const totalSteps = 6;

  const calculateProgress = () => (activeStep / totalSteps) * 100;

  return (
    <div className="relative min-h-screen flex flex-col bg-background/95">
      <AppHeader />

      <main className="container flex-1 py-8">
        {/* Hero Section */}
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h1 className="text-4xl font-bold tracking-tight mb-4">Set Up Your New Device</h1>
          <p className="text-xl text-muted-foreground">
            Follow this step-by-step guide to get your Raspberry Pi ready for AirCast
          </p>

          {/* Progress Indicator */}
          <div className="mt-8 space-y-2">
            <div className="flex justify-between text-sm">
              <span>Progress</span>
              <span>{Math.round(calculateProgress())}%</span>
            </div>
            <Progress value={calculateProgress()} className="w-full" />
          </div>
        </div>

        {/* Prerequisites Card */}
        <Card className="mb-8 max-w-3xl mx-auto">
          <CardHeader>
            <div className="flex items-center gap-2">
              <Info className="h-5 w-5 text-blue-500" />
              <CardTitle>Before You Begin</CardTitle>
            </div>
          </CardHeader>
          <div className="p-6">
            <div className="grid gap-4 sm:grid-cols-2">
              <div className="flex items-start gap-2">
                <div className="rounded-full p-1 bg-green-100">
                  <Check className="h-4 w-4 text-green-600" />
                </div>
                <div>
                  <h3 className="font-medium">Hardware</h3>
                  <p className="text-sm text-muted-foreground">MicroSD card (8GB+, Class 10)</p>
                  <p className="text-sm text-muted-foreground">Card reader</p>
                </div>
              </div>
              <div className="flex items-start gap-2">
                <div className="rounded-full p-1 bg-green-100">
                  <Check className="h-4 w-4 text-green-600" />
                </div>
                <div>
                  <h3 className="font-medium">Software</h3>
                  <p className="text-sm text-muted-foreground">Computer with internet</p>
                  <p className="text-sm text-muted-foreground">SSH client installed</p>
                </div>
              </div>
            </div>
          </div>
        </Card>

        {/* Steps Accordion */}
        <div className="max-w-3xl mx-auto">
          <Accordion type="single" collapsible defaultValue="step-1">
            {/* Step 1: Download Imager */}
            <AccordionItem value="step-1">
              <AccordionTrigger onClick={() => setActiveStep(1)} className="hover:no-underline">
                <div className="flex items-center gap-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-background">1</div>
                  <div className="text-left">
                    <h3 className="font-semibold">Download Raspberry Pi Imager</h3>
                    <p className="text-sm text-muted-foreground">Get the official imaging tool</p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-12 pb-6">
                <div className="space-y-4">
                  <img src={imagerJpg} alt="Raspberry Pi Imager" className="rounded-lg shadow-lg mb-6" />
                  <ol className="space-y-3 list-decimal pl-4">
                    <li>
                      Visit{' '}
                      <a href="https://www.raspberrypi.org/software/" className="text-primary hover:underline">
                        Raspberry Pi Imager
                      </a>
                    </li>
                    <li>Download the version for your operating system</li>
                    <li>Install following your OS instructions</li>
                  </ol>
                </div>
              </AccordionContent>
            </AccordionItem>

            {/* Step 2: Flash SD Card */}
            <AccordionItem value="step-2">
              <AccordionTrigger onClick={() => setActiveStep(2)} className="hover:no-underline">
                <div className="flex items-center gap-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-background">2</div>
                  <div className="text-left">
                    <h3 className="font-semibold">Flash the MicroSD Card</h3>
                    <p className="text-sm text-muted-foreground">Install the operating system</p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-12 pb-6">
                <ol className="space-y-4 list-decimal pl-4">
                  <li>Insert MicroSD card into your computer</li>
                  <li>Open Raspberry Pi Imager</li>
                  <li>Select "Raspberry Pi OS (other)" → "Raspberry Pi OS Lite (64-bit)"</li>
                  <li>Choose your SD card</li>
                  <li>Click "Write" to begin flashing</li>
                </ol>
                <div className="mt-4 p-4 bg-muted rounded-lg">
                  <div className="flex gap-2">
                    <Timer className="h-5 w-5 text-muted-foreground" />
                    <p className="text-sm text-muted-foreground">This process typically takes 5-10 minutes</p>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="step-3">
              <AccordionTrigger onClick={() => setActiveStep(3)} className="hover:no-underline">
                <div className="flex items-center gap-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-background">3</div>
                  <div className="text-left">
                    <h3 className="font-semibold">Configure OS Settings</h3>
                    <p className="text-sm text-muted-foreground">Set up SSH, hostname, and network</p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-12 pb-6">
                <div className="space-y-6">
                  <div className="flex items-start gap-4 p-4 bg-muted rounded-lg">
                    <Settings className="h-5 w-5 mt-1 text-muted-foreground" />
                    <p className="text-sm text-muted-foreground">
                      Click the gear icon in Raspberry Pi Imager before writing to configure these settings. This saves
                      time compared to manual configuration later.
                    </p>
                  </div>

                  <div className="grid gap-4 sm:grid-cols-2">
                    <div className="space-y-3">
                      <h4 className="font-medium">Required Settings</h4>
                      <ul className="space-y-2">
                        <li className="flex items-center gap-2">
                          <Check className="h-4 w-4 text-green-500" />
                          <span>Enable SSH access</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <Check className="h-4 w-4 text-green-500" />
                          <span>Set device hostname</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <Check className="h-4 w-4 text-green-500" />
                          <span>Configure username/password</span>
                        </li>
                      </ul>
                    </div>
                    <div className="space-y-3">
                      <h4 className="font-medium">Network Setup</h4>
                      <ul className="space-y-2">
                        <li className="flex items-center gap-2">
                          <Wifi className="h-4 w-4 text-blue-500" />
                          <span>Enter Wi-Fi SSID</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <Wifi className="h-4 w-4 text-blue-500" />
                          <span>Enter Wi-Fi password</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <Wifi className="h-4 w-4 text-blue-500" />
                          <span>Select your country</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            {/* Step 4: Boot Up */}
            <AccordionItem value="step-4">
              <AccordionTrigger onClick={() => setActiveStep(4)} className="hover:no-underline">
                <div className="flex items-center gap-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-background">4</div>
                  <div className="text-left">
                    <h3 className="font-semibold">Boot Your Device</h3>
                    <p className="text-sm text-muted-foreground">Start up your Raspberry Pi</p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-12 pb-6">
                <div className="space-y-6">
                  <ol className="space-y-4 list-decimal pl-4">
                    <li>Insert the flashed MicroSD card into your Raspberry Pi</li>
                    <li>Connect power to your Raspberry Pi</li>
                    <li>Wait for the initial boot process (about 90 seconds)</li>
                  </ol>

                  <div className="flex items-start gap-4 p-4 bg-muted rounded-lg">
                    <Power className="h-5 w-5 mt-1 text-muted-foreground" />
                    <div className="space-y-2">
                      <p className="text-sm font-medium">Boot Indicators</p>
                      <ul className="text-sm text-muted-foreground space-y-1">
                        <li>• Green LED will flash during SD card access</li>
                        <li>• Red LED indicates power connection</li>
                        <li>• Wait for LED activity to stabilize</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            {/* Step 5: Connect */}
            <AccordionItem value="step-5">
              <AccordionTrigger onClick={() => setActiveStep(5)} className="hover:no-underline">
                <div className="flex items-center gap-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-background">5</div>
                  <div className="text-left">
                    <h3 className="font-semibold">Connect to Your Device</h3>
                    <p className="text-sm text-muted-foreground">Establish SSH connection</p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-12 pb-6">
                <div className="space-y-6">
                  <div className="space-y-4">
                    <h4 className="font-medium">Find Your Device</h4>
                    <ul className="space-y-3">
                      <li className="flex items-start gap-3">
                        <div className="rounded-full p-1 bg-blue-100 mt-1">
                          <Check className="h-4 w-4 text-blue-600" />
                        </div>
                        <div className="w-full">
                          <p className="font-medium pb-2">Option 1: Using hostname</p>
                          <CodeSnippet>ssh pi@raspberrypi.local</CodeSnippet>
                        </div>
                      </li>
                      <li className="flex items-start gap-3">
                        <div className="rounded-full p-1 bg-blue-100 mt-1">
                          <Check className="h-4 w-4 text-blue-600" />
                        </div>
                        <div className="w-full">
                          <p className="font-medium">Option 2: Using IP address</p>
                          <p className="text-sm text-muted-foreground mb-2">
                            Use{' '}
                            <a href="https://angryip.org/" className="text-primary hover:underline">
                              Angry IP Scanner
                            </a>{' '}
                            or check your router's device list
                          </p>
                          <CodeSnippet>ssh pi@192.168.1.xxx</CodeSnippet>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="p-4 bg-muted rounded-lg">
                    <h4 className="font-medium mb-2">Default Credentials</h4>
                    <div className="grid gap-2 text-sm text-muted-foreground">
                      <div className="grid grid-cols-2">
                        <span>Username:</span>
                        <code>pi</code>
                      </div>
                      <div className="grid grid-cols-2">
                        <span>Password:</span>
                        <code>raspberry</code>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>

            {/* Step 6: Install AirCast */}
            <AccordionItem value="step-6">
              <AccordionTrigger onClick={() => setActiveStep(6)} className="hover:no-underline">
                <div className="flex items-center gap-4">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-background">6</div>
                  <div className="text-left">
                    <h3 className="font-semibold">Install AirCast Agent</h3>
                    <p className="text-sm text-muted-foreground">Final setup and configuration</p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-12 pb-6">
                <div className="space-y-6">
                  <div>
                    <h4 className="font-medium mb-2">1. Add GPG Key</h4>
                    <CodeSnippet>
                      curl -s https://aircast-apt.s3.us-east-1.amazonaws.com/public-key.gpg | sudo gpg --dearmor -o
                      /usr/share/keyrings/aircast.gpg
                    </CodeSnippet>
                  </div>

                  <div>
                    <h4 className="font-medium mb-2">2. Add Repository</h4>
                    <CodeSnippet>
                      echo "deb [signed-by=/usr/share/keyrings/aircast.gpg]
                      https://aircast-apt.s3.us-east-1.amazonaws.com unstable main" | sudo tee
                      /etc/apt/sources.list.d/aircast-agent.list
                    </CodeSnippet>
                  </div>

                  <div>
                    <h4 className="font-medium mb-2">3. Install and Start</h4>
                    <div className="space-y-4">
                      <CodeSnippet>sudo apt-get update && sudo apt-get install -y aircast-agent</CodeSnippet>
                      <CodeSnippet>sudo aircast-agent signin</CodeSnippet>
                      <CodeSnippet>
                        sudo systemctl enable aircast-agent && sudo systemctl start aircast-agent
                      </CodeSnippet>
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium mb-2">Troubleshooting</h4>
                    <div className="text-sm text-muted-foreground space-y-2">
                      <p>If you encounter any issues during installation:</p>
                      <ol className="list-decimal list-inside space-y-1">
                        <li>Make sure your system architecture (arm64/armhf) is supported</li>
                        <li>Verify the GPG key was imported correctly</li>
                        <li>
                          Check system logs: <CodeSnippet>sudo journalctl -u aircast-agent -f</CodeSnippet>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </main>

      <AppFooter />
    </div>
  );
}
