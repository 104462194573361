import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LaptopIcon, AlertCircle, ChevronLeft, HelpCircle } from 'lucide-react';

import { useVerifyDeviceMutation } from '../redux/devices.api.ts';
import { Button } from './ui/Button';
import { AppHeader } from './AppHeader.tsx';
import { Alert, AlertDescription, AlertTitle } from './ui/Alert';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/Card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/Tooltip';
import { useState } from 'react';
import { SetupCompletePage } from './SetupCompletePage.tsx';
import { VerifyDeviceForm, VerifyDeviceFormData } from './VerifyDeviceForm.tsx';
import { isErrorWithMessage } from 'src/lib/utils.ts';

export function VerifyPage() {
  const { token } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [verifyDevice, { isLoading, isError, error }] = useVerifyDeviceMutation();

  if (!token) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Alert variant="destructive" className="w-[400px]">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Invalid Link</AlertTitle>
          <AlertDescription>
            The verification link appears to be invalid or expired. Please try setting up your device again.
          </AlertDescription>
          <Button variant="outline" className="mt-4 w-full" onClick={() => navigate('/')}>
            Return to Dashboard
          </Button>
        </Alert>
      </div>
    );
  }

  const onSubmit = async (data: VerifyDeviceFormData) => {
    try {
      await verifyDevice({ token, deviceName: data.deviceName }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      console.error('Failed to verify the device:', error);
    }
  };

  if (isSuccess) {
    return <SetupCompletePage />;
  }

  return (
    <div className="relative min-h-screen flex flex-col bg-background">
      <Helmet>
        <title>Verify Device - AirCast</title>
      </Helmet>
      <AppHeader />

      <main className="flex-1 flex items-center justify-center p-4">
        <Card className="w-full max-w-md mx-auto">
          <CardHeader className="space-y-1">
            <div className="flex items-center justify-between">
              <Button variant="ghost" size="sm" className="gap-2" onClick={() => navigate('/')}>
                <ChevronLeft className="h-4 w-4" /> Back
              </Button>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" size="icon">
                      <HelpCircle className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="text-sm">Choose a memorable name for your device</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            <div className="flex justify-center mb-2">
              <div className="p-3 rounded-full bg-primary/10">
                <LaptopIcon className="h-6 w-6 text-primary" />
              </div>
            </div>
            <CardTitle className="text-2xl text-center">Name Your Device</CardTitle>
            <CardDescription className="text-center">
              Give your device a unique name to easily identify it later
            </CardDescription>
          </CardHeader>

          <CardContent>
            {isError && (
              <Alert variant="destructive" className="mb-4">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                  {isErrorWithMessage(error) ? error.data.message : 'Failed to register the device. Please try again.'}
                </AlertDescription>
              </Alert>
            )}
            <VerifyDeviceForm isLoading={isLoading} onSubmit={onSubmit} />
          </CardContent>

          <CardFooter className="flex flex-col space-y-2 text-center">
            <p className="text-sm text-muted-foreground">
              This name will be used to identify your device in the dashboard
            </p>
          </CardFooter>
        </Card>
      </main>
    </div>
  );
}
