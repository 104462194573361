import { API_URL } from '../env.ts';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

import { Card, CardContent, CardFooter, CardHeader, CardTitle, CardDescription } from './ui/Card.tsx';
import { Button } from './ui/Button.tsx';
import { GoogleSvg } from './icons/GoogleSvg.tsx';
import { Separator } from './ui/Separator';

export function SignInPage() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative px-4">
      {/* Background Elements */}
      <div className="absolute inset-0 -z-10 bg-gradient-to-br from-background via-background to-primary/5" />
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.primary/1),transparent)]" />

      <div className="w-full max-w-md">
        {/* Brand Section */}
        <div className="text-center mb-8">
          <motion.h1
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            className="text-4xl font-bold tracking-tight mb-2"
          >
            Welcome to AirCast
          </motion.h1>
          <p className="text-muted-foreground">Stream and manage your devices with ease</p>
        </div>

        <Card className="w-full">
          <CardHeader className="space-y-1">
            <CardTitle className="text-2xl text-center">Sign in</CardTitle>
            <CardDescription className="text-center">Choose your preferred sign in method</CardDescription>
          </CardHeader>

          <CardContent className="space-y-4">
            <motion.div whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}>
              <a href={`${API_URL}/auth/google`}>
                <Button variant="outline" size="lg" className="w-full relative group hover:border-primary/50">
                  <div className="flex items-center justify-center gap-3">
                    <GoogleSvg className="h-5 w-5" />
                    <span>Continue with Google</span>
                    <ArrowRight className="h-4 w-4 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all" />
                  </div>
                </Button>
              </a>
            </motion.div>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <Separator className="w-full" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">Protected by AirCast</span>
              </div>
            </div>
          </CardContent>

          <CardFooter className="flex flex-col space-y-4 text-center">
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{' '}
              <a href="https://aircast.one/terms" className="underline underline-offset-4 hover:text-primary">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="https://aircast.one/privacy" className="underline underline-offset-4 hover:text-primary">
                Privacy Policy
              </a>
              .
            </p>
          </CardFooter>
        </Card>

        {/* Help Text */}
        <p className="mt-4 px-8 text-center text-sm text-muted-foreground">
          Need help?{' '}
          <a href="https://aircast.one/support" className="font-medium text-primary hover:underline">
            Contact Support
          </a>
        </p>
      </div>
    </div>
  );
}
