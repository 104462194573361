import { Navigate, Outlet } from 'react-router-dom';

import { Toaster } from './ui/Sonner.tsx';

export const SignedInLayout = () => {
  const user = localStorage.getItem('user');

  if (!user) {
    // Store the original URL the user is trying to access
    localStorage.setItem('redirectAfterLogin', window.location.pathname);
    // Redirect to the sign-in page if no token is found
    return <Navigate to="/sign-in" replace />;
  }

  return (
    <>
      <Outlet />
      <Toaster />
    </>
  );
};
