import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { cn } from 'src/lib/utils';

import { Button } from './Button.tsx';

interface Props {
  href: string;
  title: string;
  disabled?: boolean;
  onOpenLink: (href: string) => void;
}

export const SidebarNavItem: FC<Props> = ({ href, title, disabled, onOpenLink }) => {
  const location = useLocation();

  return (
    <Button
      variant="link"
      disabled={disabled}
      onClick={() => onOpenLink(href)}
      className={cn(
        location.pathname.includes(href) ? 'bg-muted hover:bg-muted' : 'hover:bg-transparent hover:underline',
        'justify-start',
      )}
    >
      {title}
    </Button>
  );
};
