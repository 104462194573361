import { CameraIcon, CameraOffIcon, RotateCcwIcon } from 'lucide-react';
import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';

import {
  selectIsRtspConnected,
  selectRtspErrorMessage,
  selectSelectedCamera,
  sendGetSelectedCamera,
  sendRtspDial,
} from '../redux/ms.slice.ts';
import { useAppDispatch, useAppSelector } from '../redux/hooks.ts';

import { Button } from './ui/Button.tsx';

export const RtspStatusItem = () => {
  const isRtspConnected = useAppSelector(selectIsRtspConnected);
  const rtspErrorMessage = useAppSelector(selectRtspErrorMessage);
  const selectedCamera = useAppSelector(selectSelectedCamera);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(sendGetSelectedCamera());
  }, [dispatch]);

  return (
    <div className="flex items-center gap-3">
      {isRtspConnected ? <CameraIcon size={32} /> : <CameraOffIcon size={32} />}
      {selectedCamera ? (
        <div className="grow">
          <div className="grow">
            Camera {capitalize(selectedCamera.name)}: {isRtspConnected ? 'Connected' : 'Disconnected'}
          </div>
          <div className="max-w-sm text-sm opacity-80">{rtspErrorMessage ?? selectedCamera.rtsp_url}</div>
        </div>
      ) : (
        <div className="grow">
          <div>Rtsp: {isRtspConnected ? 'Connected' : 'Disconnected'}</div>
          {rtspErrorMessage && <div className="max-w-sm text-sm opacity-80">{rtspErrorMessage}</div>}
        </div>
      )}
      <div>
        <Button variant="ghost" className="gap-3" onClick={() => dispatch(sendRtspDial())}>
          <RotateCcwIcon size={16} className="drop-shadow-[-1px_1px_0px_rgba(0,0,0,0.25)" />
          Re-Dial
        </Button>
      </div>
    </div>
  );
};
