import { useState, useEffect } from 'react';
import { Settings as SettingsIcon, Cable } from 'lucide-react';
import { ComponentProps, FC } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { cn } from '../lib/utils.ts';
import { Device } from '../redux/devices.api.ts';
import { Button } from './ui/Button.tsx';
import { Card } from './ui/Card.tsx';
import { Badge } from './ui/Badge.tsx';

interface Props extends ComponentProps<typeof Card> {
  isOnline?: boolean;
  device: Device;
  onConnect: () => void;
  onOpenSettings: () => void;
}

export const DeviceCard: FC<Props> = ({ device, onConnect, isOnline, onOpenSettings }) => {
  const [timeAgo, setTimeAgo] = useState<string>('');

  useEffect(() => {
    const updateTimeAgo = () => {
      let lastSeenDate = new Date(device.last_seen_at);
      const now = new Date();

      if (lastSeenDate > now) {
        // Adjust future dates to now
        lastSeenDate = now;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setTimeAgo(formatDistanceToNow(lastSeenDate, { addSuffix: true }));
    };

    updateTimeAgo();
    const timer = setInterval(updateTimeAgo, 1000); // Update every second for accuracy

    return () => clearInterval(timer);
  }, [device.last_seen_at]);

  return (
    <div className="flex items-center justify-between rounded-lg border bg-card p-4 hover:bg-accent/50 transition-colors">
      {/* Device Info */}
      <div className="flex sm:items-center gap-2 sm:gap-4 flex-col sm:flex-row">
        <div>
          <h3 className="font-medium">{device.name}</h3>
        </div>

        <Badge variant={isOnline ? 'default' : 'secondary'} className="w-fit">
          <span
            className={cn(
              'mr-1.5 inline-block h-2 w-2 rounded-full',
              isOnline ? 'bg-green-500' : 'bg-muted-foreground',
            )}
          />
          {isOnline ? 'Online' : timeAgo}
        </Badge>
      </div>

      {/* Actions */}
      <div className="flex items-center gap-2">
        <Button className="gap-2" variant={isOnline ? 'default' : 'secondary'} onClick={onConnect}>
          <Cable className="h-4 w-4" />
          Connect
        </Button>
        <Button variant="ghost" size="icon" onClick={onOpenSettings}>
          <SettingsIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default DeviceCard;
