interface RTCStatsReport {
  type: string;
  nominated: string;
  currentRoundTripTime: number;
  mediaType: string;
  bytesReceived: number;
  timestamp: number;
  bitrate: number;
  rtt: number;
}

export type RTPStreamStats = RTCInboundRtpStreamStats & {
  bitrate: number;
  rtt: number;
  freezeCount?: number;
};
export const RTSP_VIDEO_ID = 99;

export const MEDIA_CONSTRAINTS = {
  offerToReceiveAudio: false,
  offerToReceiveVideo: true,
};

export const ICE_SERVERS = [
  {
    urls: 'stun:stun.l.google.com:19302',
  },
  {
    urls: 'turn:turn.aircast.one:3478',
    username: 'dev',
    credential: 'raspberry',
  },
];

export const addTrackToElement = (element: HTMLVideoElement, mediaStreamTrack: MediaStreamTrack) => {
  (element.srcObject as unknown as RTCPeerConnection).addTrack(mediaStreamTrack.clone());
};

export const listenForStats = (
  pc: RTCPeerConnection,
  track: MediaStreamTrack,
  callback?: (stats: RTCStatsReport) => void,
) => {
  let previousBytesReceived = 0;
  let previousTimestamp = 0;
  let calculatedBitRate = 0;

  setInterval(async () => {
    const reports = (await pc.getStats(track)) as unknown as Array<RTCStatsReport>;

    let rtt = 0;

    reports.forEach((report) => {
      if (report.type === 'candidate-pair' && report.nominated && report.currentRoundTripTime) {
        rtt = report.currentRoundTripTime;
      }
      if (report.type !== 'inbound-rtp') {
        return;
      }

      if (report.mediaType !== 'video') {
        return callback?.({ ...report, bitrate: calculatedBitRate, rtt });
      }

      const bytesReceived = report.bytesReceived;
      const timestamp = report.timestamp;

      if (previousBytesReceived !== 0 && previousTimestamp !== 0) {
        const timeInSeconds = (timestamp - previousTimestamp) / 1000;
        calculatedBitRate = ((bytesReceived - previousBytesReceived) * 8) / timeInSeconds;
      }

      previousBytesReceived = bytesReceived;
      previousTimestamp = timestamp;

      if (calculatedBitRate === 0 || calculatedBitRate === Number.POSITIVE_INFINITY) return;
      callback?.({ ...report, bitrate: calculatedBitRate, rtt });
    });
  }, 2000);
};
