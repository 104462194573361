import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from 'lucide-react';

import { useAppSelector } from '../redux/hooks.ts';
import { selectModemInfo } from '../redux/ms.slice.ts';
import { selectVideoQuality } from '../redux/webrtc.slice.ts';

import { Button } from './ui/Button.tsx';
import { SignalIndicator } from './SignalIndicator.tsx';
import { DeviceMenuDrawer } from './DeviceMenuDrawer.tsx';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/Tooltip.tsx';
import isEmpty from 'lodash/isEmpty';

export const DeviceHeader = () => {
  const modemInfo = useAppSelector(selectModemInfo);
  const videoQuality = useAppSelector(selectVideoQuality);

  return (
    <header className="fixed z-10 flex w-full items-center gap-5 p-4">
      <Button size="icon" asChild variant="ghost">
        <Link to="/">
          <ChevronLeftIcon className="size-6 text-white" />
        </Link>
      </Button>
      <div className="flex grow gap-2"></div>
      <div className="flex items-center gap-6">
        {!isEmpty(modemInfo?.signal_quality) && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div>
                  <SignalIndicator label={modemInfo!.access_technologies} strength={modemInfo!.signal_quality} />
                </div>
              </TooltipTrigger>
              <TooltipContent className="flex flex-col gap-1">
                <div className="flex gap-2">
                  <div className="grow font-medium">Signal Quality </div>
                  <div>{modemInfo!.signal_quality}%</div>
                </div>
                <div className="flex gap-2">
                  <div className="grow font-medium">Operator</div>
                  <div>{modemInfo!.m3gpp_operator_name.split(' ')[0]}</div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {videoQuality && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <SignalIndicator label={videoQuality.label} strength={videoQuality.percent} />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="flex gap-2">
                  <div className="grow font-medium">Video Quality</div>
                  <div>{videoQuality.percent.toFixed(0)}%</div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <DeviceMenuDrawer />
    </header>
  );
};
