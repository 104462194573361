import { createApi } from '@reduxjs/toolkit/query/react';

import { BindGeneralFormValues } from 'src/components/GeneralForm.tsx';

import baseQuery from './baseQuery.ts';

export interface Device {
  id: string;
  name: string;
  last_seen_at: string;
  registered_at: string;
}

const deviceApi = createApi({
  reducerPath: 'devices',
  tagTypes: ['Devices', 'Device'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getDevice: builder.query<Device, string>({
      query: (device_id) => ({ url: `/auth/devices/${device_id}` }),
      providesTags: ['Devices'],
    }),
    getDevices: builder.query<Array<Device>, null>({
      query: () => ({ url: `/auth/devices` }),
      providesTags: ['Devices'],
    }),
    updateDeviceName: builder.mutation<Device, BindGeneralFormValues>({
      query: ({ deviceName, deviceId }) => ({
        url: `/auth/devices/${deviceId}/name`,
        method: 'PUT',
        body: { device_name: deviceName },
      }),
      invalidatesTags: ['Devices'],
    }),
    deleteDevice: builder.mutation({
      query: (device_id) => ({
        url: `/auth/devices/${device_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Devices'],
    }),
    verifyDevice: builder.mutation<void, { token: string; deviceName: string }>({
      query: ({ token, deviceName }) => ({
        url: `/auth/devices/verify/${token}`,
        method: 'POST',
        body: { device_name: deviceName },
      }),
    }),
  }),
});

export const {
  useGetDeviceQuery,
  useGetDevicesQuery,
  useUpdateDeviceNameMutation,
  useDeleteDeviceMutation,
  useVerifyDeviceMutation,
} = deviceApi;

export default deviceApi;
