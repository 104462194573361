import { AlertCircle } from 'lucide-react';
import { ComponentProps, FC } from 'react';

import { Alert, AlertDescription, AlertTitle } from './ui/Alert.tsx';

export const ErrorAlert: FC<ComponentProps<typeof Alert> & { message: string }> = ({ className, message }) => (
  <Alert className={className} variant="destructive">
    <AlertCircle className="h-4 w-4" />
    <AlertTitle>Error</AlertTitle>
    <AlertDescription>{message}</AlertDescription>
  </Alert>
);
