import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs));
}

export function extractErrorCode(message: string): string | null {
  const start = message.indexOf('[');
  const end = message.indexOf(']');

  if (start !== -1 && end !== -1) {
    return message.substring(start + 1, end);
  }

  return null;
}

export function isErrorWithMessage(error: unknown): error is { data: { message: string } } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error as any).data === 'object' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    'message' in (error as any).data
  );
}
