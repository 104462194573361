import { Label } from './ui/Label.tsx';
import { Input } from './ui/Input.tsx';
import { Button } from './ui/Button.tsx';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';

export type VerifyDeviceFormData = {
  deviceName: string;
};

type Props = {
  isLoading: boolean;
  onSubmit: (values: VerifyDeviceFormData) => Promise<void>;
};

export const VerifyDeviceForm = ({ isLoading, onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<VerifyDeviceFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="deviceName">
            Device Name
            <span className="text-destructive">*</span>
          </Label>
          <Input
            id="deviceName"
            placeholder="e.g., Talon"
            {...register('deviceName', {
              required: 'Please enter a device name',
              minLength: {
                value: 3,
                message: 'Device name must be at least 3 characters',
              },
              maxLength: {
                value: 50,
                message: 'Device name must not exceed 50 characters',
              },
            })}
            className="w-full"
          />
          {errors.deviceName && <p className="text-sm text-destructive">{errors.deviceName.message}</p>}
        </div>

        <Button type="submit" className="w-full" disabled={isLoading || isSubmitting}>
          {isLoading || isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Verifying...
            </>
          ) : (
            'Complete Setup'
          )}
        </Button>
      </div>
    </form>
  );
};
