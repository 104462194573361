import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import { ApiError } from 'src/redux/types.ts';

import { useDeleteDeviceMutation, useGetDeviceQuery, useUpdateDeviceNameMutation } from '../redux/devices.api.ts';

import { DeleteDeviceZone } from './DeleteDeviceZone.tsx';
import { ErrorAlert } from './ErrorAlert.tsx';
import { BindGeneralFormValues, GeneralForm } from './GeneralForm.tsx';
import { Separator } from './ui/Separator';

export const SettingsGeneralPage: FC = () => {
  const navigate = useNavigate();
  const { device_id } = useParams<{ device_id: string }>();
  const { data: device, error } = useGetDeviceQuery(String(device_id));
  const [bindUpdateDevice, { error: updateError, status }] = useUpdateDeviceNameMutation();
  const [bindDeleteDevice, { error: deleteError, isSuccess }] = useDeleteDeviceMutation();

  useEffect(() => {
    if (error && (error as ApiError).status === 404) navigate('/404');
  }, [error, navigate]);

  useEffect(() => {
    if (!isSuccess) return;
    navigate('/');
  }, [isSuccess, navigate]);

  const updateDevice = (value: BindGeneralFormValues): void => {
    void bindUpdateDevice(value);
  };

  const deleteDevice = (): void => {
    if (!device) return;
    void bindDeleteDevice(device.id);
  };

  return (
    <div className="space-y-6">
      <Helmet>
        <title>General Settings</title>
      </Helmet>

      <div>
        <h3 className="text-lg font-medium">General</h3>
        <p className="text-sm text-muted-foreground">
          Update your device settings. Set your preferred device name or stop using this device.
        </p>
      </div>
      <Separator />

      <GeneralForm status={status} device={device} onSubmit={updateDevice} />
      {updateError && 'data' in updateError && (
        <ErrorAlert className="my-2" message={(updateError as ApiError).data.message} />
      )}

      <DeleteDeviceZone deviceName={device?.name} onDelete={deleteDevice} />
      {deleteError && 'data' in deleteError && (
        <ErrorAlert className="my-2" message={(updateError as ApiError).data.message} />
      )}
    </div>
  );
};
