import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

export const HeaderLink: FC<PropsWithChildren<{ to: string }>> = ({ to, children }) => {
  return (
    <Link className="pl-2 text-sm font-medium text-muted-foreground transition-colors hover:text-primary" to={to}>
      {children}
    </Link>
  );
};
