import { ClipboardIcon, Check, Terminal } from 'lucide-react';
import { useState } from 'react';

import { Button } from './ui/Button.tsx';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/Tooltip';
import { cn } from '../lib/utils';

type Props = {
  children: string;
  label?: string;
  copyMessage?: string;
  showIcon?: boolean;
  className?: string;
};

export const CodeSnippet = ({ children, label, copyMessage = 'Copied!', showIcon = true, className }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children).catch(console.error);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className={cn('group relative', className)}>
      {label && <div className="mb-2 text-sm font-medium text-muted-foreground">{label}</div>}
      <div className="relative flex items-center rounded-md bg-black/95 p-4 font-mono text-sm text-white shadow-sm">
        {showIcon && <Terminal className="mr-2 h-4 w-4 text-muted-foreground/70" />}
        <div className="mr-12 flex-1 overflow-x-auto whitespace-nowrap scrollbar-none">{children}</div>
        <div className="absolute right-2 top-2.5">
          <TooltipProvider>
            <Tooltip open={copied} delayDuration={0}>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  variant="ghost"
                  className={cn(
                    'h-8 w-8 text-muted-foreground hover:bg-white/10 hover:text-white',
                    copied && 'text-green-500',
                  )}
                  onClick={handleCopy}
                >
                  {copied ? <Check className="h-3.5 w-3.5" /> : <ClipboardIcon className="h-3.5 w-3.5" />}
                </Button>
              </TooltipTrigger>
              <TooltipContent side="top" className="bg-primary text-primary-foreground">
                <p className="text-xs">{copied ? copyMessage : 'Copy to clipboard'}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};
