import mitt, { Emitter } from 'mitt';

import { ICE_SERVERS } from './webrtc';

type Events = {
  latency: number;
  telemetry: unknown;
  track: { track: MediaStreamTrack; peerConnection: RTCPeerConnection };
  icecandidate: RTCIceCandidateInit;
  signalingstatechange: RTCSignalingState;
  connectionstatechange: RTCPeerConnectionState;
  negotiationneeded: RTCPeerConnection;
  iceconnectionstatechange: RTCIceConnectionState;
  error: Error;
  'terminal-channel': RTCDataChannel;
};

export default class PeerConnection {
  private emitter: Emitter<Events> = mitt();
  private peerConnection: RTCPeerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });

  constructor() {
    this.initLatencyChannel();

    this.peerConnection.addTransceiver('video', { direction: 'recvonly' });

    this.peerConnection.addEventListener('datachannel', (event) => {
      const dataChannel = event.channel;
      if (dataChannel.label === 'terminal') {
        this.emitter.emit('terminal-channel', dataChannel);
      }

      if (dataChannel.label === 'telemetry') {
        dataChannel.addEventListener('message', (evt) => {
          const arrayBuffer = evt.data;
          const decoder = new TextDecoder('utf-8');
          const msg = JSON.parse(decoder.decode(arrayBuffer));
          this.emitter.emit('telemetry', msg);
        });
      }
    });

    this.attachNegotiationEvents();
  }

  private attachNegotiationEvents(): void {
    this.peerConnection.addEventListener('negotiationneeded', (event) => {
      const peerConnection = event.currentTarget as RTCPeerConnection;
      this.emitter.emit('negotiationneeded', peerConnection);
    });

    this.peerConnection.addEventListener('track', (event) => {
      const peerConnection = event.currentTarget as RTCPeerConnection;
      this.emitter.emit('track', { track: event.track, peerConnection });
    });

    this.peerConnection.addEventListener('icecandidate', (event) => {
      if (event.candidate) {
        this.emitter.emit('icecandidate', event.candidate.toJSON());
      }
    });

    this.peerConnection.addEventListener('signalingstatechange', (event) => {
      const peerConnection = event.currentTarget as RTCPeerConnection;
      this.emitter.emit('signalingstatechange', peerConnection.signalingState);
    });

    this.peerConnection.addEventListener('connectionstatechange', (event) => {
      const peerConnection = event.currentTarget as RTCPeerConnection;
      this.emitter.emit('connectionstatechange', peerConnection.connectionState);
    });

    this.peerConnection.addEventListener('iceconnectionstatechange', (event) => {
      const peerConnection = event.currentTarget as RTCPeerConnection;
      this.emitter.emit('iceconnectionstatechange', peerConnection.iceConnectionState);
    });
  }

  private initLatencyChannel() {
    const latencyChannel = this.peerConnection.createDataChannel('latency');
    let interval: number | null = null;

    latencyChannel.onerror = this.handleLatencyChannelError;

    latencyChannel.addEventListener('open', () => {
      interval = setInterval(() => latencyChannel.send(Date.now().toString()), 1000);
    });

    latencyChannel.onmessage = (event: MessageEvent<string>) => {
      const receiveTime = Date.now();
      const sentTime = Number.parseFloat(event.data);
      const latency = receiveTime - sentTime;
      this.emitter.emit('latency', latency);
    };

    latencyChannel.addEventListener('close', () => {
      if (interval) {
        clearInterval(interval);
      }
    });
  }

  private handleLatencyChannelError = (event: Event) => {
    console.error('event handleLatencyChannelError', event);
  };

  onNegotiationNeeded(callback: (peerConnection: RTCPeerConnection) => void) {
    this.emitter.on('negotiationneeded', callback);
  }

  onLatency(callback: (latency: number) => void) {
    this.emitter.on('latency', callback);
  }

  onTrack(
    callback: ({ track, peerConnection }: { track: MediaStreamTrack; peerConnection: RTCPeerConnection }) => void,
  ) {
    this.emitter.on('track', callback);
  }

  onIceCandidate(callback: (candidate: RTCIceCandidateInit) => void) {
    this.emitter.on('icecandidate', callback);
  }

  onSignalingStateChange(callback: (signalingState: RTCSignalingState) => void) {
    this.emitter.on('signalingstatechange', callback);
  }

  onConnectionStateChange(callback: (signalingState: RTCPeerConnectionState) => void) {
    this.emitter.on('connectionstatechange', callback);
  }

  onIceConnectionStateChange(callback: (iceConnectionState: RTCIceConnectionState) => void) {
    this.emitter.on('iceconnectionstatechange', callback);
  }

  onTerminalChannel(callback: (iceConnectionState: RTCDataChannel) => void) {
    this.emitter.on('terminal-channel', callback);
  }

  restartIce() {
    this.peerConnection.restartIce();
  }

  setRemoteDescription(description: RTCSessionDescriptionInit) {
    return this.peerConnection.setRemoteDescription(description);
  }

  addIceCandidate(iceCandidate: RTCIceCandidateInit) {
    return this.peerConnection.addIceCandidate(iceCandidate);
  }

  async createAnswer(offerSDP: RTCSessionDescription) {
    if (this.peerConnection.signalingState != 'stable') {
      await Promise.all([
        this.peerConnection.setLocalDescription({ type: 'rollback' }),
        this.peerConnection.setRemoteDescription(offerSDP),
      ]);
    } else {
      await this.peerConnection.setRemoteDescription(offerSDP);
    }

    const answer = await this.peerConnection.createAnswer();
    await this.peerConnection.setLocalDescription(answer).catch(console.error);

    return this.peerConnection?.localDescription as RTCSessionDescriptionInit;
  }

  async createOffer() {
    const offer = await this.peerConnection.createOffer({ iceRestart: true });
    await this.peerConnection.setLocalDescription(offer).catch(console.error);

    return this.peerConnection?.localDescription as RTCSessionDescriptionInit;
  }

  close() {
    this.emitter.all.clear(); // Clear all events registered in mitt
    this.peerConnection.close();
  }
}
