import { ApiMessage, PiMessage } from 'src/lib/messages';

import { API_URL } from '../env';

type DeviceMessage = { type: string; payload?: unknown };

export default class DeviceWebSocket {
  private ws: WebSocket;
  private intentionalClose: boolean;

  constructor(device_id: string) {
    this.ws = new WebSocket(`${API_URL.replace('http', 'ws')}/auth/devices/${device_id}/websocket`);
    this.intentionalClose = false;
  }

  send(message: DeviceMessage) {
    if (!message.payload) message.payload = null;
    this.ws.send(JSON.stringify(message));
    return this;
  }

  onOpen(listener: (message: Event) => void): DeviceWebSocket {
    this.ws.addEventListener('open', listener);
    return this;
  }

  onMessage(listener: (message: ApiMessage | PiMessage) => void): DeviceWebSocket {
    this.ws.addEventListener('message', (e) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      listener(JSON.parse(e.data.toString()));
    });
    return this;
  }

  onUnexpectedClose(callback: () => void) {
    this.ws.addEventListener('close', () => {
      if (!this.intentionalClose) {
        callback();
      }
    });
    return this;
  }

  onDisconnect(callback: () => void) {
    this.ws.addEventListener('close', () => {
      if (this.intentionalClose) {
        callback();
      }
    });
    return this;
  }

  disconnect() {
    this.intentionalClose = true;
    this.ws.close();
    return this;
  }
}
