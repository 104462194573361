import { Middleware, PayloadAction } from '@reduxjs/toolkit';

import { ApiMessage, PiMessage } from 'src/lib/messages';
import DeviceWebSocket from 'src/lib/DeviceWebSocket.ts';

import * as msSlice from './ms.slice.ts';

const mapMessageToAction = (message: ApiMessage | PiMessage) => {
  switch (message.type) {
    case 'pi:modem_connect_success':
      return msSlice.receivedPiModemConnected;
    case 'pi:modem_info':
      return msSlice.receivedPiModemInfo;
    case 'pi:rtsp_connect_success':
      return msSlice.receivedRtspConnected;
    case 'pi:rtsp_stream_success':
      return msSlice.receivedRtspStreamReady;
    case 'pi:rtsp_describe_error':
      return msSlice.receivedRtspDescribe;
    case 'pi:mavlink_connect_success':
      return msSlice.receivedMavlinkConnected;
    case 'api:pi_connected':
      return msSlice.receivedPiConnected;
    case 'api:pi_disconnected':
      return msSlice.receivedPiDisconnected;
    case 'pi:modem_signal_quality_info':
      return msSlice.receivedPiModemSignalQuality;
    case 'pi:rtsp_error':
      return msSlice.receivedRtspError;
    case 'pi:rtsp_dial_error':
      return msSlice.receivedRtspDialError;
    case 'pi:webrtc_answer':
      return msSlice.receivedPiAnswer;
    case 'pi:webrtc_icecandidate_info':
      return msSlice.receivedPiIceCandidate;
    case 'pi:webrtc_peer_connect_success':
      return msSlice.receivedWebRtcPeerConnected;
    case 'pi:webrtc_peer_disconnect_warning':
      return msSlice.receivedWebRtcPeerDisconnected;
    case 'pi:webrtc_session_start_success':
      return msSlice.receivedWebrtcSessionStarted;
    case 'pi:webrtc_ice_connect_success':
      return msSlice.receivedWebrtcIceConnected;
    case 'pi:webrtc_ice_disconnect_warning':
      return msSlice.receivedWebrtcIceDisconnected;
    case 'pi:webrtc_offer_ack':
      return msSlice.receivedPiOfferAck;
    case 'pi:webrtc_answer_ack':
      return msSlice.receivedPiAnswerAck;
    case 'pi:webrtc_offer_info':
      return msSlice.receivedPiWebrtcOffer;
    case 'pi:icecandidate_ack':
      return msSlice.receivedPiIcecandidateAck;
    case 'pi:start_webrtc_session_ack':
      return msSlice.receivedStartWebRTCSessionRequestAck;
    case 'pi:rtsp_publish_error':
      return msSlice.receivedRtspPublishError;
    case 'pi:rtsp_packet_lost_warning':
      return msSlice.receivedRtspPacketLostError;
    case 'pi:rtsp_decode_error':
      return msSlice.receivedRtspDecodeError;
    case 'pi:webrtc_error':
      return msSlice.receivedPiWebRtcError;
    case 'pi:mavlink_dial_error':
      return msSlice.receivedPiMavlinkDialError;
    case 'pi:modem_connection_error':
      return msSlice.receivedPiModemConnectionError;
    case 'pi:get_status_ack':
      return msSlice.receivedPiStatusAck;
    case 'pi:webrtc_offer_error':
      return msSlice.receivedPiWebrtcOfferError;
    case 'pi:get_modem_info_ack':
      return msSlice.receivedPiModemModemInfoAck;
    case 'pi:rtsp_listen_error':
      return msSlice.receivedPiRtspListenError;
    case 'pi:rtsp_client_error':
      return msSlice.receivedPiRtspClientError;
    case 'pi:get_camera_list_ack':
      return msSlice.receivedGetCameraListAck;
    case 'pi:add_camera_ack':
      return msSlice.receivedAddCameraAck;
    case 'pi:add_camera_error':
      return msSlice.receivedAddCameraError;
    case 'pi:update_camera_ack':
      return msSlice.receivedUpdateCameraAck;
    case 'pi:remove_camera_ack':
      return msSlice.receivedRemoveCameraAck;
    case 'pi:get_camera_list_error':
      return msSlice.receivedGetCameraListError;
    case 'pi:update_camera_error':
      return msSlice.receivedUpdateCameraError;
    case 'pi:remove_camera_error':
      return msSlice.receivedRemoveCameraError;
    case 'pi:switch_camera_ack':
      return msSlice.receivedSwitchCameraAck;
    case 'pi:switch_camera_error':
      return msSlice.receivedSwitchCameraError;
    case 'pi:get_selected_camera_ack':
      return msSlice.receivedGetSelectedCameraAck;
    case 'pi:get_selected_camera_error':
      return msSlice.receivedGetSelectedCameraError;
    case 'pi:rtsp_disconnect_warning':
      return msSlice.receivedPiRtspDisconnectWarning;
    case 'pi:get_network_interfaces_ack':
      return msSlice.receivedGetNetworkInterfacesAck;
    case 'pi:rtsp_connect_fail_error':
      return msSlice.receivedRtspConnectFailError;
    case 'pi:webrtc_session_stop_warning':
      return msSlice.receivedWebrtcSessionStopWarning;
    case 'pi:rtsp_redial_error':
      return msSlice.receivedRtspRedialError;
    case 'pi:webrtc_peer_connecting_info':
      return msSlice.receivedPeerConnectingInfo;
    default:
      return null;
  }
};

let websocket: DeviceWebSocket | null;

const msMiddleware: Middleware = (api) => (next) => (_action) => {
  const action = _action as PayloadAction<never>;
  const response = next(action);

  if (action.type == msSlice.connectToWebsocket.toString()) {
    const device_id = action.payload;
    websocket = new DeviceWebSocket(device_id)
      .onOpen(() => api.dispatch(msSlice.receivedWebsocketConnected()))
      .onDisconnect(() => api.dispatch(msSlice.receiveWebsocketDisconnected()))
      .onUnexpectedClose(() => api.dispatch(msSlice.receiveWebsocketClosedUnexpectedly()))
      .onMessage((message) => {
        const messageAction = mapMessageToAction(message);
        if (messageAction) {
          api.dispatch(messageAction(message.payload as never));
        } else {
          console.warn('message skipped: ', message);
        }
      });
  }

  if (action.type === msSlice.sendRtspDial.toString()) {
    websocket?.send({ type: 'web:redial_rtsp', payload: action.payload });
  }

  if (action.type === msSlice.sendGetNetworkInterfaces.toString()) {
    websocket?.send({ type: 'web:get_network_interfaces' });
  }

  if (action.type === msSlice.sendGetSelectedCamera.toString()) {
    websocket?.send({ type: 'web:get_selected_camera' });
  }

  if (action.type === msSlice.sendGetCameraList.toString()) {
    websocket?.send({ type: 'web:get_camera_list' });
  }

  if (action.type === msSlice.sendAddCamera.toString()) {
    websocket?.send({ type: 'web:add_camera', payload: action.payload });
  }

  if (action.type === msSlice.sendUpdateCamera.toString()) {
    websocket?.send({ type: 'web:update_camera', payload: action.payload });
  }

  if (action.type === msSlice.sendRemoveCamera.toString()) {
    websocket?.send({ type: 'web:remove_camera', payload: action.payload });
  }

  if (action.type === msSlice.sendSwitchCamera.toString()) {
    websocket?.send({ type: 'web:switch_camera', payload: action.payload });
  }

  if (action.type === msSlice.sendStartWebRtcSessionRequest.toString()) {
    websocket?.send({ type: 'web:start_webrtc_session' });
  }

  if (action.type === msSlice.sendOffer.toString()) {
    const payload = action.payload as RTCSessionDescriptionInit;
    websocket?.send({ type: 'web:webrtc_offer', payload: payload.sdp });
  }

  if (action.type === msSlice.sendAnswer.toString()) {
    const payload = action.payload as RTCSessionDescriptionInit;
    websocket?.send({ type: 'web:webrtc_answer', payload: payload.sdp });
  }

  if (action.type === msSlice.sendIceCandidate.toString()) {
    websocket?.send({ type: 'web:webrtc_icecandidate', payload: action.payload });
  }

  if (action.type === msSlice.sendReboot.toString()) {
    websocket?.send({ type: 'web:reboot' });
  }

  if (action.type === msSlice.sendPiStatusRequest.toString()) {
    websocket?.send({ type: 'web:get_status' });
  }

  if (action.type === msSlice.sendPiModemInfoRequest.toString()) {
    websocket?.send({ type: 'web:get_modem_info' });
  }

  if (action.type === msSlice.disconnectWebsocket.toString()) {
    if (!websocket) {
      return console.warn('Closing connection that does not exist!');
    }
    websocket.disconnect();
  }

  return response;
};

export default msMiddleware;
