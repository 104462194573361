import { Helmet } from 'react-helmet';
import { AppHeader } from './AppHeader.tsx';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/Card.tsx';
import { CheckCircle2, Server } from 'lucide-react';
import { Button } from './ui/Button.tsx';
import { useNavigate } from 'react-router-dom';

export const SetupCompletePage = () => {
  const navigate = useNavigate();

  return (
    <div className="relative min-h-screen flex flex-col bg-background">
      <Helmet>
        <title>Setup Complete - AirCast</title>
      </Helmet>
      <AppHeader />

      <main className="flex-1 flex items-center justify-center p-4">
        <Card className="w-full max-w-lg mx-auto">
          <CardHeader className="space-y-1">
            <div className="flex justify-center mb-2">
              <div className="p-3 rounded-full bg-green-100 dark:bg-green-900">
                <CheckCircle2 className="h-6 w-6 text-green-600 dark:text-green-400" />
              </div>
            </div>
            <CardTitle className="text-2xl text-center">Setup Complete!</CardTitle>
            <CardDescription className="text-center">
              Your device has been registered and configured successfully.
            </CardDescription>
          </CardHeader>

          <CardContent className="space-y-6">
            <div className="rounded-lg border bg-muted p-4">
              <div className="flex items-center gap-3 text-primary">
                <Server className="h-5 w-5" />
                <div className="space-y-1">
                  <p className="text-sm font-medium">Service Status</p>
                  <div className="flex items-center gap-2">
                    <p className="text-sm text-muted-foreground">
                      AirCast Agent is running and configured to start automatically
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>

          <CardFooter className="flex flex-col space-y-2">
            <Button variant="outline" className="w-full" onClick={() => navigate('/')}>
              Return to Dashboard
            </Button>
          </CardFooter>
        </Card>
      </main>
    </div>
  );
};
