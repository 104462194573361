import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthCallbackPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');

    // Get the query parameters from the URL
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const email = params.get('email');
    const name = params.get('name');
    const picture = params.get('picture');

    // Store the token and user information in localStorage or context
    if (id) {
      localStorage.setItem('user', JSON.stringify({ id, email, name, picture }));
      localStorage.removeItem('redirectAfterLogin');
      // Redirect to the dashboard or home page after storing the info
      navigate(redirectAfterLogin ?? '/');
    } else {
      // Handle error, e.g., missing token
      navigate('/sign-in');
    }
  }, [location, navigate]);

  return <div>Logging you in...</div>;
};

export default AuthCallbackPage;
