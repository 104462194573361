import { ComponentProps, FC, useEffect, useRef } from 'react';

type Props = ComponentProps<'video'> & {
  track: MediaStreamTrack;
};

export const LiveVideo: FC<Props> = ({ track, ...props }) => {
  const element = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const element_ = element.current;
    if (!element_) return console.warn('No video element!');
    if (!track) return console.warn('No video track!');
    element_.srcObject = new MediaStream([track]);
    element_.play().catch(console.error);
  }, [track]);

  return <video {...props} id="webrtc-output" ref={element} playsInline poster="data:image/gif,AAAA" muted autoPlay />;
};
