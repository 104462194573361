import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import createSagaMiddleware from 'redux-saga';

import msMiddleware from './ms.middleware';
import { msSlice } from './ms.slice';
import { sseSlice } from './sse.slice';
import saga from './sagas';
import deviceApi from './devices.api';
import tokensApi from './tokens.api';
import webrtcMiddleware from './webrtc.middleware';
import { webrtcSlice } from './webrtc.slice';
import sseMiddleware from './sse.middleware';
import { terminalSlice } from './terminal.slice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools: true,
  reducer: {
    [deviceApi.reducerPath]: deviceApi.reducer,
    [tokensApi.reducerPath]: tokensApi.reducer,
    ms: msSlice.reducer,
    sse: sseSlice.reducer,
    webrtc: webrtcSlice.reducer,
    terminal: terminalSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      deviceApi.middleware,
      tokensApi.middleware,
      msMiddleware,
      sseMiddleware,
      webrtcMiddleware,
      sagaMiddleware,
    ] as const),
});

sagaMiddleware.run(saga);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
