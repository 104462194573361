import isString from 'lodash/isString';

import { cn } from 'src/lib/utils.ts';

import { Log, LogType } from '../redux/types.ts';

const variantsMap: Record<LogType, string> = {
  error: 'text-red-300',
  success: 'text-green-300',
  warning: 'text-yellow-300',
  info: 'text-blue-300',
  debug: 'text-gray-300',
};

type Props = {
  className?: string;
  logs: Log[];
};

export const Logs = ({ className, logs }: Props) => {
  return (
    <div className={cn('flex grow flex-col text-gray-300', className)}>
      {logs.map((logEvent, index) => (
        <div className="flex gap-2 text-sm" key={index}>
          <div className={variantsMap[logEvent[0]]}>[{logEvent[1]}]</div>
          {isString(logEvent[2]) ? logEvent[2] : JSON.stringify(logEvent[2])} {logEvent[3] && <div>x{logEvent[3]}</div>}
        </div>
      ))}
    </div>
  );
};
