import { UnplugIcon } from 'lucide-react';

import { cn } from 'src/lib/utils.ts';
import { useAppSelector } from 'src/redux/hooks.ts';
import { selectIsDeviceConnected } from 'src/redux/ms.slice.ts';

import { RtspStatusItem } from './RtspStatusItem.tsx';
import { WebRtcStatusItem } from './WebRtcStatusItem.tsx';
import { DeviceStatusItem } from './DeviceStatusItem.tsx';
import { MavlinkStatusItem } from './MavlinkStatusItem.tsx';
import { ModemStatusItem } from './ModemStatusItem.tsx';

type Props = {
  deviceName?: string;
  className?: string;
};

export const DeviceStatusPanel = ({ deviceName, className }: Props) => {
  const isDeviceConnected = useAppSelector(selectIsDeviceConnected);

  if (!isDeviceConnected) {
    return (
      <div className={cn('flex flex-col space-y-3', className)}>
        {!isDeviceConnected && (
          <div className="flex flex-col items-center justify-center gap-3">
            <UnplugIcon size={60} />
            <div>Device not connected</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={cn('flex flex-col space-y-3 bg-background/50 p-6 rounded-md w-full max-w-[480px]', className)}>
      <DeviceStatusItem deviceName={deviceName} />
      <RtspStatusItem />
      <WebRtcStatusItem />
      <MavlinkStatusItem />
      <ModemStatusItem />
    </div>
  );
};
